import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Chip,
  IconButton,
} from "@mui/material";
import {
  Check as CheckIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
} from "@mui/icons-material";

const MCQQuestion = ({
  question,
  questionIndex,
  selectedAnswer,
  handleAnswerSelect,
  onEdit,
  onDelete,
  onMoveUp,
  onMoveDown,
  editableQuestions,
  themeMode,
}) => {
  const isCorrect = selectedAnswer === question.correctAnswer;

  return (
    <Card
      variant="outlined"
      sx={{
        position: "relative", // Position the card relative for ribbon positioning
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginBottom: 2,
        gap: 2,
        backgroundColor: themeMode === "light" ? "#ffffff" : "#2c2c2c",
        borderRadius: "8px",
        boxShadow:
          themeMode === "light"
            ? "0 1px 4px rgba(0, 0, 0, 0.1)"
            : "0 1px 4px rgba(0, 0, 0, 0.2)",
        border: "1px solid transparent",
      }}
    >
      {/* Ribbon */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "primary.main",
          color: "white",
          px: 1.5,
          py: 0.5,
          fontSize: "12px",
          fontWeight: "bold",
          zIndex: 1,
          borderBottomRightRadius: "8px",
        }}
      >
        {`Q${questionIndex + 1}`}
      </Box>

      <CardContent
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box sx={{ mb: 2, mt: 3 }}>
          <Typography
            variant="h6"
            sx={{ textAlign: "center", marginBottom: 2 }}
          >
            {question.question}
          </Typography>

          {/* Render the options */}
          {question.options.map((option, optionIndex) => {
            const isSelected = selectedAnswer === option;
            const showCorrect =
              selectedAnswer && option === question.correctAnswer;
            const showIncorrect = isSelected && !isCorrect;

            return (
              <Typography
                key={`render-option-${questionIndex}-${optionIndex}`}
                sx={{
                  border: 2,
                  borderColor: showCorrect
                    ? "success.main"
                    : showIncorrect
                      ? "error.main"
                      : "inherit",
                  color: showCorrect
                    ? "success.main"
                    : showIncorrect
                      ? "error.main"
                      : "inherit",
                  borderWidth: 2,
                  padding: "8px 16px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  borderRadius: 1,
                  transition: "background-color 0.2s ease",
                  "&:hover": {
                    backgroundColor: showCorrect
                      ? "success.light"
                      : showIncorrect
                        ? "error.light"
                        : "action.hover",
                  },
                  mb: 1,
                }}
                onClick={() => handleAnswerSelect(questionIndex, option)}
              >
                {option}
                {showCorrect && <CheckIcon />}
              </Typography>
            );
          })}
        </Box>

        {/* Show explanation and correct answer */}
        {selectedAnswer && (
          <Box>
            <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
              Correct Answer: <strong>{question.correctAnswer}</strong>
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
              Explanation: <strong>{question.explanation}</strong>
            </Typography>
          </Box>
        )}

        {/* Add skill tag and cognitive level chips */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            gap: 1,
            mt: 2,
            flexWrap: "wrap",
          }}
        >
          {question.skill_tag && (
            <Chip
              label={question.skill_tag}
              size="small"
              color="primary"
              variant="outlined"
            />
          )}
          {question.cognitive_level && (
            <Chip
              label={
                question.cognitive_level === "HOTS"
                  ? "Higher Order Thinking"
                  : "Lower Order Thinking"
              }
              size="small"
              color="primary"
              variant="outlined"
            />
          )}
          {question.key_concept && (
            <Chip
              label={question.key_concept}
              size="small"
              color="primary"
              variant="outlined"
            />
          )}
        </Box>

        {/* Action Buttons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 1,
            mt: 2,
          }}
        >
          <IconButton
            onClick={onMoveUp}
            size="small"
            disabled={questionIndex === 0}
          >
            <ArrowUpwardIcon />
          </IconButton>
          <IconButton
            onClick={onMoveDown}
            size="small"
            disabled={questionIndex === editableQuestions.length - 1}
          >
            <ArrowDownwardIcon />
          </IconButton>
          <IconButton onClick={onEdit} size="small">
            <EditIcon />
          </IconButton>
          <IconButton onClick={onDelete} size="small" color="error">
            <DeleteIcon />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
};

export default MCQQuestion;

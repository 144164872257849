import React, { useMemo, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Grid,
  Avatar,
  LinearProgress,
} from "@mui/material";
import Lottie from "react-lottie";
import correctAnimation from "./animations/correctAnswer.json";
import incorrectAnimation from "./animations/incorrectAnswer.json";

// Fisher-Yates shuffle algorithm for shuffling options
const shuffleArray = (array) => {
  let shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

// LobbyView component to display when the quiz hasn't started yet
const LobbyView = ({ title, avatar }) => (
  <Box
    sx={{
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
      <Avatar
        src={avatar}
        alt="Your Avatar"
        sx={{ width: 56, height: 56, mr: 2 }}
      />
      <Typography variant="h6">{title}</Typography>
    </Box>
    <Card elevation={3} sx={{ mt: 4 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Welcome to the Quiz Lobby!
        </Typography>
        <Typography variant="body1">
          The quiz will begin shortly. Please wait for the host to start the
          quiz.
        </Typography>
        <Box sx={{ mt: 4 }}>
          <LinearProgress />
        </Box>
      </CardContent>
    </Card>
  </Box>
);

// ParticipantView component
const ParticipantView = ({
  currentQuestion,
  currentQuestionIndex,
  totalQuestions,
  title,
  answer,
  setAnswer,
  handleSubmitAnswer,
  hasSubmitted,
  showAnswers,
  correctAnswer,
  explanation,
  avatar,
  socket,
  quizCode,
  isLobbyView, // Receive isLobbyView as a prop from App.js
}) => {
  // Remove local lobbyview state
  // Ensure options exist before attempting to shuffle
  const shuffledOptions = useMemo(() => {
    return currentQuestion && currentQuestion.options
      ? shuffleArray(currentQuestion.options)
      : [];
  }, [currentQuestion]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // For most browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Since isLobbyView is managed by App.js, we don't need to listen to socket events here

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // Display the LobbyView if the quiz hasn't started yet
  if (isLobbyView) {
    return <LobbyView title={title} avatar={avatar} />;
  }

  // Display a loading message if the currentQuestion is not available yet
  if (!currentQuestion) {
    return <Typography>Loading....</Typography>;
  }

  // Main participant view during the quiz
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box display="flex" alignItems="center" mb={2}>
        <Box>
          <Typography variant="h6">{title}</Typography>
        </Box>
      </Box>
      {currentQuestion && (
        <Card
          elevation={3}
          sx={{ position: "relative", width: "100%", borderRadius: "8px" }}
        >
          <CardContent>
            {showAnswers && answer === correctAnswer && (
              <Box sx={{ position: "absolute", top: 5, right: 8 }}>
                <Lottie
                  options={{ animationData: correctAnimation }}
                  height={50}
                  width={50}
                />
              </Box>
            )}
            {showAnswers && answer !== correctAnswer && (
              <Box sx={{ position: "absolute", top: 5, right: 5 }}>
                <Lottie
                  options={{ animationData: incorrectAnimation }}
                  height={50}
                  width={50}
                />
              </Box>
            )}
            {/* Ribbon */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                backgroundColor: "primary.main",
                color: "white",
                px: 1.5,
                py: 0.5,
                fontSize: "12px",
                fontWeight: "bold",
                zIndex: 1,
                borderBottomRightRadius: "8px",
              }}
            >
              {`Q${currentQuestionIndex + 1} of ${totalQuestions}`}
            </Box>
            <Typography variant="h6" gutterBottom sx={{ mt: 5 }}>
              {currentQuestion.question}
            </Typography>
            <Grid container spacing={2}>
              {shuffledOptions.length > 0 ? (
                shuffledOptions.map((option, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Button
                      variant={answer === option ? "contained" : "outlined"}
                      color={
                        showAnswers
                          ? option === correctAnswer
                            ? "success"
                            : answer === option
                              ? "error"
                              : "primary"
                          : "primary"
                      }
                      onClick={() => setAnswer(option)}
                      fullWidth
                      disabled={hasSubmitted || showAnswers}
                      sx={{
                        borderWidth: 2,
                        borderColor: showAnswers
                          ? option === correctAnswer
                            ? "success.main"
                            : answer === option
                              ? "error.main"
                              : "primary.main"
                          : "primary.main",
                        borderStyle: "solid",
                      }}
                    >
                      {option}
                      {showAnswers && option === correctAnswer && " ✓"}
                    </Button>
                  </Grid>
                ))
              ) : (
                <Typography variant="body1" color="error.main">
                  No options available for this question.
                </Typography>
              )}
            </Grid>
          </CardContent>
          <CardActions>
            {!showAnswers && (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSubmitAnswer}
                disabled={!answer || hasSubmitted}
                fullWidth
              >
                Submit Answer
              </Button>
            )}
          </CardActions>
        </Card>
      )}

      {hasSubmitted && !showAnswers && (
        <Typography variant="body1" color="success.main" sx={{ mt: 2 }}>
          Answer submitted successfully! Waiting for results...
        </Typography>
      )}
      {showAnswers && (
        <Box mt={2}>
          <Typography
            variant="body1"
            color={answer === correctAnswer ? "success.main" : "error.main"}
          >
            {answer === correctAnswer
              ? "Correct!"
              : `Incorrect. The correct answer was: ${correctAnswer}`}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            Explanation: {explanation}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ParticipantView;

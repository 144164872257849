import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Grid,
  Avatar,
  Paper,
  Tabs,
  Tab,
  IconButton,
  Tooltip,
  CircularProgress,
  Dialog,
  DialogContent,
  Badge,
  Chip,
  alpha,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ShareIcon from "@mui/icons-material/Share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import QrCodeIcon from "@mui/icons-material/QrCode";
import QRCode from "react-qr-code";
import ClassIcon from "@mui/icons-material/Class";
import SubjectIcon from "@mui/icons-material/Subject";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  RemoveCircle as RemoveCircleIcon,
  HelpOutline as HelpOutlineIcon,
} from "@mui/icons-material";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import Lobby from "./Lobby";

const CircularProgressWithLabel = (props) => {
  return (
    <Box position="relative" display="inline-flex">
      {/* Unfilled CircularProgress to act as a skeleton */}
      <CircularProgress
        variant="determinate"
        value={100}
        size={80}
        sx={{ color: "grey.300", position: "absolute" }}
      />
      {/* Actual progress CircularProgress */}
      <CircularProgress variant="determinate" size={80} {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h6" component="div" color="textSecondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const HostView = ({
  socket,
  title,
  quizCode,
  totalParticipants,
  currentQuestion,
  currentQuestionIndex,
  totalQuestions,
  results,
  answer,
  hasSubmitted,
  correctAnswer,
  explanation,
  showAnswers,
  setShowAnswers,
  responses,
  participants,
  scores,
  isHost,
  handleNextQuestion,
  handleSubmitAnswer,
  setAnswer,
  participantId,
  scoreGrid,
  activeQuizSession,
  setActiveQuizSession,
  reloadQuizSession,
  mode,
  isLobbyView,
  themeMode,
  classroom,
  subject,
  setClassroom,
  setSubject,
  mixpanel,
}) => {
  const [tabValue, setTabValue] = useState(0);
  const [openQrDialog, setOpenQrDialog] = useState(false);

  const handleShareQuizLink = async () => {
    const url = `/join/${quizCode}`;
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Join Quiz",
          text: `Join the quiz using the following code: ${quizCode}`,
          url: url,
        });

        // Track share quiz link event
        mixpanel.track("Quiz Link Shared", {
          quizCode: quizCode,
          participantId: participantId,
          method: "navigator.share",
          timestamp: new Date().toISOString(),
        });
      } catch (err) {
        console.error("Error sharing:", err);

        // Track error event
        mixpanel.track("Error Sharing Quiz Link", {
          quizCode: quizCode,
          participantId: participantId,
          errorMessage: err.message,
          timestamp: new Date().toISOString(),
        });
      }
    } else {
      alert("Sharing is not supported on this browser.");

      // Track unsupported share method
      mixpanel.track("Share Method Unsupported", {
        quizCode: quizCode,
        participantId: participantId,
        method: "navigator.share",
        timestamp: new Date().toISOString(),
      });
    }
  };

  const handleCopyQuizLink = () => {
    navigator.clipboard.writeText(`${window.location.origin}/join/${quizCode}`);
    alert("Quiz link copied to clipboard!");

    // Track copy quiz link event
    mixpanel.track("Quiz Link Copied", {
      quizCode: quizCode,
      participantId: participantId,
      timestamp: new Date().toISOString(),
    });
  };

  // Initialize quizStarted based on isLobbyView
  const [quizStarted, setQuizStarted] = useState(!isLobbyView);

  // Update quizStarted whenever isLobbyView changes
  useEffect(() => {
    setQuizStarted(!isLobbyView);
  }, [isLobbyView]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);

    // Track tab change event
    mixpanel.track("Analysis Tab Changed", {
      quizCode: quizCode,
      participantId: participantId,
      tabIndex: newValue,
      timestamp: new Date().toISOString(),
    });
  };

  const handleStartQuiz = () => {
    setQuizStarted(true);

    // Emit the event to set lobbyview to false on the server
    socket.emit("setLobbyviewFalse", { quizCode: quizCode });

    // Track quiz start event
    mixpanel.track("Quiz Started", {
      quizCode: quizCode,
      participantId: participantId,
      timestamp: new Date().toISOString(),
    });
  };

  const handleShowAnswer = () => {
    socket.emit("submitAnswer", { quizCode, answer: null, participantId });
    setShowAnswers(true);

    // Track show answer event
    mixpanel.track("Answer Shown", {
      quizCode: quizCode,
      questionIndex: currentQuestionIndex,
      participantId: participantId,
      timestamp: new Date().toISOString(),
    });
  };

  const handleOpenQrDialog = () => {
    setOpenQrDialog(true);

    // Track open QR code dialog event
    mixpanel.track("QR Dialog Opened", {
      quizCode: quizCode,
      participantId: participantId,
      timestamp: new Date().toISOString(),
    });
  };

  const handleCloseQrDialog = () => {
    setOpenQrDialog(false);

    // Track close QR code dialog event
    mixpanel.track("QR Dialog Closed", {
      quizCode: quizCode,
      participantId: participantId,
      timestamp: new Date().toISOString(),
    });
  };

  const handleNextQuestionWithTracking = () => {
    handleNextQuestion();

    // Track next question event
    mixpanel.track("Next Question", {
      quizCode: quizCode,
      nextQuestionIndex: currentQuestionIndex + 1,
      participantId: participantId,
      timestamp: new Date().toISOString(),
    });
  };

  // Sort participants based on their score (correct answers)
  const sortedParticipants = [...participants].sort((a, b) => {
    const scoreA = scores[a.id]?.correct || 0;
    const scoreB = scores[b.id]?.correct || 0;
    return scoreB - scoreA;
  });

  useEffect(() => {
    // Track component mount
    mixpanel.track("HostView Mounted", {
      quizCode: quizCode,
      participantId: participantId,
      timestamp: new Date().toISOString(),
    });

    return () => {
      // Track component unmount
      mixpanel.track("HostView Unmounted", {
        quizCode: quizCode,
        participantId: participantId,
        timestamp: new Date().toISOString(),
      });
    };
  }, []);

  if (!quizStarted) {
    // If the quiz hasn't started, show the Lobby component
    return (
      <Lobby
        socket={socket}
        quizCode={quizCode}
        participants={participants}
        handleStartQuiz={handleStartQuiz}
        activeQuizSession={activeQuizSession}
        participantId={participantId}
        themeMode={themeMode}
        setClassroom={setClassroom}
        setSubject={setSubject}
        mixpanel={mixpanel}
      />
    );
  } else {
    // If the quiz has started, show the active quiz view
    return (
      <Box
        sx={{
          width: "90%",
          marginLeft: "0px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          elevation={2}
          sx={{
            width: "100%",
            mt: 3,
            mb: 3,
            borderRadius: 2,
            backgroundColor: (theme) => theme.palette.background.paper,
            border: (theme) =>
              `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
            transition: "all 0.3s ease-in-out",
          }}
        >
          {activeQuizSession && (
            <Box sx={{ p: 2.5 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: 2,
                }}
              >
                <Box sx={{ flex: 1, minWidth: 280 }}>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 600,
                      mb: 1.5,
                      color: (theme) => theme.palette.text.primary,
                      fontSize: { xs: "1.2rem", sm: "1.5rem" },
                    }}
                  >
                    {`${activeQuizSession.title}`}
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      fontWeight: 500,
                      mb: 2,
                      fontSize: { xs: "1rem", sm: "1.3rem" },
                    }}
                  >
                    Join Code: {activeQuizSession.quizCode}
                  </Typography>

                  <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                    <Chip
                      label={classroom}
                      color="primary"
                      variant="outlined"
                      size="small"
                      icon={<ClassIcon />}
                      sx={{
                        borderRadius: 1.5,
                        "& .MuiChip-label": { px: 1.5 },
                        "& .MuiChip-icon": { fontSize: 18 },
                      }}
                    />
                    <Chip
                      label={subject}
                      color="secondary"
                      variant="outlined"
                      size="small"
                      icon={<SubjectIcon />}
                      sx={{
                        borderRadius: 1.5,
                        "& .MuiChip-label": { px: 1.5 },
                        "& .MuiChip-icon": { fontSize: 18 },
                      }}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    borderRadius: 2,
                    p: 1,
                  }}
                >
                  <Tooltip title="Share Quiz Link" arrow>
                    <IconButton
                      onClick={handleShareQuizLink}
                      color="primary"
                      sx={{
                        "&:hover": {
                          backgroundColor: (theme) =>
                            alpha(theme.palette.primary.main, 0.15),
                        },
                      }}
                    >
                      <ShareIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Copy Quiz Link" arrow>
                    <IconButton
                      onClick={handleCopyQuizLink}
                      color="primary"
                      sx={{
                        "&:hover": {
                          backgroundColor: (theme) =>
                            alpha(theme.palette.primary.main, 0.15),
                        },
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Show QR Code" arrow>
                    <IconButton
                      onClick={handleOpenQrDialog}
                      color="primary"
                      sx={{
                        "&:hover": {
                          backgroundColor: (theme) =>
                            alpha(theme.palette.primary.main, 0.15),
                        },
                      }}
                    >
                      <QrCodeIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="End Quiz" arrow>
                    <IconButton
                      color="error"
                      onClick={() => {
                        const confirmClose = window.confirm(
                          "Are you sure you want to stop the quiz session?",
                        );
                        if (confirmClose) {
                          socket.emit("endQuizSession", {
                            quizCode: `${activeQuizSession.quizCode}`,
                          });
                          setActiveQuizSession(null);

                          // Track end quiz event
                          mixpanel.track("Quiz Ended", {
                            quizCode: quizCode,
                            participantId: participantId,
                            timestamp: new Date().toISOString(),
                          });
                        }
                      }}
                      sx={{
                        "&:hover": {
                          backgroundColor: (theme) =>
                            alpha(theme.palette.error.main, 0.15),
                        },
                      }}
                    >
                      <ExitToAppIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          )}
        </Card>

        <Dialog
          open={openQrDialog}
          onClose={handleCloseQrDialog}
          PaperProps={{
            style: {
              width: "auto",
              height: "auto",
              padding: 0,
              borderRadius: 8,
            },
          }}
        >
          <DialogContent
            style={{
              padding: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <QRCode
                value={`${window.location.origin}/join/${quizCode}`}
                size={400}
                style={{
                  backgroundColor: themeMode === "light" ? "#fff" : "#333",
                }}
              />
            </Box>
          </DialogContent>
        </Dialog>

        <Card
          elevation={1}
          sx={{ position: "relative", width: "100%", borderRadius: "8px" }}
        >
          <CardContent>
            <Box sx={{ width: "100%" }}>
              {/* Ribbon */}
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  backgroundColor: "primary.main",
                  color: "white",
                  px: 1.5,
                  py: 0.5,
                  fontSize: "12px",
                  fontWeight: "bold",
                  zIndex: 1,
                  borderBottomRightRadius: "8px",
                }}
              >
                {`Q${currentQuestionIndex + 1} of ${totalQuestions}`}
              </Box>
              {currentQuestion && (
                <Box mt={3}>
                  <Typography variant="h4" align="center">
                    {currentQuestion.question}
                  </Typography>
                </Box>
              )}

              {showAnswers ? (
                <Box
                  mt={2}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Typography variant="h6" color="success.main" align="center">
                    Correct Answer: {correctAnswer}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ mt: 2 }}
                    align="center"
                  >
                    Explanation: {explanation}
                  </Typography>
                </Box>
              ) : (
                <Box
                  mt={2}
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                >
                  <CircularProgressWithLabel
                    value={
                      totalParticipants > 0
                        ? (responses / totalParticipants) * 100
                        : 0
                    }
                  />
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    {responses} / {totalParticipants}
                  </Typography>
                </Box>
              )}
            </Box>
          </CardContent>
          <CardActions>
            {isHost && (
              <>
                {showAnswers ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNextQuestionWithTracking}
                    fullWidth
                  >
                    Next Question
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleShowAnswer}
                    fullWidth
                  >
                    Show Answer
                  </Button>
                )}
              </>
            )}
          </CardActions>
        </Card>

        <Paper
          elevation={1}
          sx={{ mt: 2, p: 2, width: "100%", borderRadius: "8px" }}
        >
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="tabs">
            <Tab label="Aggregate View" />
            <Tab label="Detailed Analysis" />
          </Tabs>

          <TabPanel value={tabValue} index={0}>
            <Grid container spacing={0} alignItems="center" mb={2}>
              {participants.map((participant) => (
                <Grid item key={participant.id}>
                  <Tooltip title={`${participant.name} (${participant.email})`}>
                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      badgeContent={
                        participant.hasAnswered ? (
                          <TurnedInIcon
                            color="primary"
                            fontSize="small"
                            style={{ marginLeft: "-15px" }}
                          />
                        ) : null
                      }
                    >
                      <Avatar
                        src={participant.avatar}
                        alt={`${participant.name} Avatar`}
                        sx={{
                          mr: 1,
                          border:
                            participant.connected === false
                              ? "4px solid red"
                              : "none",
                        }}
                      />
                    </Badge>
                  </Tooltip>
                </Grid>
              ))}
            </Grid>

            <Grid container spacing={2}>
              {currentQuestion &&
                currentQuestion.options.map((option) => {
                  const count = results[option] || 0;
                  const percentage =
                    totalParticipants > 0
                      ? ((count / totalParticipants) * 100).toFixed(2)
                      : 0;

                  return (
                    <Grid item xs={12} key={option}>
                      <Box
                        sx={{
                          borderRadius: 1,
                          padding: 1,
                          borderRadius: "8px",
                        }}
                      >
                        <Typography variant="body1">{option}</Typography>
                        <Typography
                          variant="body2"
                          sx={{ display: "block", mt: 1 }}
                        >{`${count} responses (${Math.round(
                          percentage,
                        )}%)`}</Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mt: 1,
                            mb: 2,
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              bgcolor: "grey.300",
                              height: 20,
                              borderRadius: 1,
                              position: "relative",
                              overflow: "hidden",
                            }}
                          >
                            <Box
                              sx={{
                                width: `${percentage}%`,
                                bgcolor:
                                  showAnswers && option === correctAnswer
                                    ? "green"
                                    : "red",
                                height: "100%",
                                borderRadius: 1,
                                transition: "width 0.5s",
                                position: "absolute",
                              }}
                            />
                          </Box>
                          <Typography variant="body2" sx={{ ml: 2 }} />
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
            </Grid>
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            {scoreGrid && scoreGrid.length > 0 && (
              <>
                <TableContainer
                  component={Paper}
                  sx={{
                    width: "100%",
                    borderRadius: 2,
                    overflowX: "auto",
                    boxShadow: (theme) =>
                      `0 0 10px ${alpha(theme.palette.primary.main, 0.1)}`,
                    border: (theme) =>
                      `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                  }}
                >
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            backgroundColor: (theme) =>
                              alpha(theme.palette.primary.main, 0.05),
                            width: "40px",
                          }}
                        ></TableCell>
                        {scoreGrid[0].scores.map((score, index) => (
                          <TableCell
                            key={index}
                            align="center"
                            sx={{
                              backgroundColor: (theme) =>
                                alpha(theme.palette.primary.main, 0.05),
                              minWidth: "40px",
                              padding: "2px",
                              transition: "min-width 0.3s ease",
                            }}
                          >
                            <Tooltip
                              title={
                                <React.Fragment>
                                  <Typography variant="body2">{`Question: ${score.question}`}</Typography>
                                  <Typography variant="caption">
                                    {score.keyConcept}
                                  </Typography>
                                </React.Fragment>
                              }
                              arrow
                              placement="top"
                            >
                              <Typography
                                variant="subtitle2"
                                sx={{ fontWeight: "bold", cursor: "pointer" }}
                              >
                                Q{index + 1}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedParticipants.map((participant) => {
                        const participantScore = scoreGrid.find(
                          (grid) => grid.participantId === participant.id,
                        );

                        return (
                          <TableRow
                            key={participant.id}
                            sx={{
                              "&:nth-of-type(odd)": {
                                backgroundColor: (theme) =>
                                  alpha(theme.palette.background.default, 0.4),
                              },
                              "&:hover": {
                                backgroundColor: (theme) =>
                                  alpha(theme.palette.primary.main, 0.04),
                              },
                              height: 50,
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                padding: "8px",
                                verticalAlign: "middle",
                              }}
                            >
                              <Tooltip
                                title={
                                  <React.Fragment>
                                    <Typography
                                      variant="body2"
                                      sx={{ fontWeight: 500 }}
                                    >
                                      {participant.id === participantId
                                        ? `${participant.name} (You)`
                                        : participant.name}
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      color="text.secondary"
                                    >
                                      {participant.email}
                                    </Typography>
                                  </React.Fragment>
                                }
                                arrow
                              >
                                <Avatar
                                  src={participant.avatar}
                                  alt={`${participant.name} avatar`}
                                  sx={{
                                    width: 40,
                                    height: 40,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    // Handle avatar click
                                    // Track avatar click event
                                    mixpanel.track(
                                      "Participant Avatar Clicked",
                                      {
                                        quizCode: quizCode,
                                        participantId: participantId,
                                        clickedParticipantId: participant.id,
                                        timestamp: new Date().toISOString(),
                                      },
                                    );
                                  }}
                                />
                              </Tooltip>
                            </TableCell>
                            {participantScore?.scores.map((score, idx) => (
                              <TableCell key={idx} align="center">
                                <Tooltip
                                  title={
                                    <React.Fragment>
                                      <Typography variant="body2">
                                        {score.status.charAt(0).toUpperCase() +
                                          score.status.slice(1)}
                                      </Typography>
                                      {score.keyConcept && (
                                        <Typography
                                          variant="caption"
                                          color="text.secondary"
                                        >
                                          {score.keyConcept}
                                        </Typography>
                                      )}
                                    </React.Fragment>
                                  }
                                  arrow
                                  placement="top"
                                >
                                  <Box
                                    component="span"
                                    sx={{
                                      display: "inline-flex",
                                      padding: "2px",
                                      borderRadius: "50%",
                                      backgroundColor: (theme) =>
                                        score.status === "correct"
                                          ? alpha(
                                              theme.palette.success.main,
                                              0.1,
                                            )
                                          : score.status === "wrong"
                                            ? alpha(
                                                theme.palette.error.main,
                                                0.1,
                                              )
                                            : score.status === "missed"
                                              ? alpha(
                                                  theme.palette.warning.main,
                                                  0.1,
                                                )
                                              : alpha(
                                                  theme.palette.grey[500],
                                                  0.1,
                                                ),
                                    }}
                                  >
                                    {score.status === "correct" && (
                                      <CheckCircleIcon
                                        color="success"
                                        sx={{ fontSize: 20 }}
                                      />
                                    )}
                                    {score.status === "wrong" && (
                                      <CancelIcon
                                        color="error"
                                        sx={{ fontSize: 20 }}
                                      />
                                    )}
                                    {score.status === "missed" && (
                                      <RemoveCircleIcon
                                        color="warning"
                                        sx={{ fontSize: 20 }}
                                      />
                                    )}
                                    {score.status === "not attempted" && (
                                      <HelpOutlineIcon
                                        color="disabled"
                                        sx={{ fontSize: 20 }}
                                      />
                                    )}
                                  </Box>
                                </Tooltip>
                              </TableCell>
                            ))}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* Legend for Icons */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 3,
                    gap: 2,
                    p: 2,
                    borderRadius: 1,
                    backgroundColor: (theme) =>
                      alpha(theme.palette.primary.main, 0.05),
                    boxShadow: (theme) =>
                      `0 0 5px ${alpha(theme.palette.divider, 0.1)}`,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <CheckCircleIcon color="success" />
                    <Typography variant="body2">Correct</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <CancelIcon color="error" />
                    <Typography variant="body2">Wrong</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <RemoveCircleIcon color="warning" />
                    <Typography variant="body2">Missed</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <HelpOutlineIcon color="disabled" />
                    <Typography variant="body2">Not Attempted</Typography>
                  </Box>
                </Box>
              </>
            )}
          </TabPanel>
        </Paper>
      </Box>
    );
  }
};

export default HostView;

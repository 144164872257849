import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Container,
  Button,
  AppBar,
  Toolbar,
  Chip,
  Divider,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Grid,
} from "@mui/material";
import QuizSyncText from "./QuizSyncText"; // Adjusted QuizSyncText component
import Typed from "typed.js";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import FeedbackIcon from "@mui/icons-material/Feedback";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Lottie from "react-lottie";
import rocketman from "./animations/rocketman.json";

const LandingPage = ({ mixpanel }) => {
  const typedElementRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);

  // State for avatars simulation
  const [avatars, setAvatars] = useState([]);
  const [responses, setResponses] = useState(0);
  const totalAvatars = 30;

  // Inside your LandingPage component
  useEffect(() => {
    mixpanel.track("Landing Page View");
  }, [mixpanel]);

  useEffect(() => {
    // Generate avatars on component mount
    const generatedAvatars = Array.from({ length: totalAvatars }, () => ({
      id: Math.random().toString(36).substr(2, 9),
      hasResponded: false,
    }));
    setAvatars(generatedAvatars);
  }, []);

  useEffect(() => {
    // Simulation of avatars submitting responses
    let intervalId;
    if (avatars.length > 0) {
      intervalId = setInterval(() => {
        setAvatars((prevAvatars) => {
          const notResponded = prevAvatars.filter((a) => !a.hasResponded);
          if (notResponded.length === 0) {
            // Reset simulation
            return prevAvatars.map((avatar) => ({
              ...avatar,
              hasResponded: false,
            }));
          }
          const randomIndex = Math.floor(Math.random() * notResponded.length);
          const avatarId = notResponded[randomIndex].id;
          return prevAvatars.map((avatar) =>
            avatar.id === avatarId ? { ...avatar, hasResponded: true } : avatar,
          );
        });
      }, 500); // Adjust the speed of simulation here
    }
    return () => clearInterval(intervalId);
  }, [avatars]);

  useEffect(() => {
    // Update the number of responses
    const respondedCount = avatars.filter((a) => a.hasResponded).length;
    setResponses(respondedCount);
  }, [avatars]);

  useEffect(() => {
    const typedOptions = {
      strings: [
        "innovation",
        "engagement",
        "interactive learning",
        "formative assessment",
      ],
      typeSpeed: 50,
      backSpeed: 25,
      loop: true,
    };

    const typed = new Typed(typedElementRef.current, typedOptions);

    return () => {
      typed.destroy();
    };
  }, []);

  const handleLogin = () => {
    mixpanel.track("Clicked Try QuizSync");
    window.location.href = "/login"; // Redirect to the login page
  };

  return (
    <Box sx={{ width: "100%" }}>
      {/* Top Navigation Bar */}
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          sx={{ backgroundColor: "#5e52f7", boxShadow: "none" }}
          elevation={0}
        >
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <Typography variant="h6">
              <QuizSyncText>QuizSync</QuizSyncText>&nbsp;&nbsp;
              <Chip
                label="EXPERIMENT"
                variant="outlined"
                size="extra-small"
                style={{
                  color: "white",
                  borderColor: "white",
                  fontSize: "0.625rem",
                }}
              />
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleLogin}
              size="small"
              sx={{
                borderRadius: "20px",
                fontFamily: "inherit",
              }}
            >
              <QuizSyncText>Try QuizSync</QuizSyncText>
            </Button>
          </Toolbar>
        </AppBar>
      </Box>

      {/* Main Content Section */}
      <Box sx={{ backgroundColor: "#5e52f7" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "400px",
            textAlign: "center",
            color: "primary.contrastText",
            padding: 3,
          }}
        >
          <Container maxWidth="sm">
            <img
              src="/logo192.png"
              alt="Logo"
              style={{ height: "140px", marginRight: "16px" }}
            />

            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontSize: { xs: "1.5rem", md: "2rem" } }}
            >
              Light up your Classroom with
            </Typography>
            <Typography
              variant="h3"
              gutterBottom
              sx={{
                fontSize: {
                  xs: "2rem",
                  sm: "2.5rem",
                  md: "3rem",
                  lg: "3.5rem",
                },
                mt: 2,
                whiteSpace: "nowrap",
                overflow: "hidden",
                fontWeight: "bold",
                background:
                  "linear-gradient(to left, #f9f9f9, #f0f0f0, #b8b8b8, #b8b8b8)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              <span ref={typedElementRef}></span>
            </Typography>

            <Typography
              variant="body1"
              sx={{ mt: 2, mb: 2, color: "#f9f9f9", fontSize: "0.75rem" }}
            >
              QuizSync is a classroom tool designed to collect real-time
              multiple-choice responses from students. Powered by AI, it
              streamlines formative assessments, generates quizzes
              automatically, and helps teachers quickly create quizzes using
              their own notes.
            </Typography>

            <Button
              variant="contained"
              color="secondary"
              onClick={handleLogin}
              size="large"
              sx={{
                borderRadius: "20px",
                fontFamily: "inherit",
              }}
            >
              <QuizSyncText>Try QuizSync</QuizSyncText>
            </Button>
          </Container>
        </Box>
      </Box>

      {/* Divider */}
      <Divider sx={{ backgroundColor: "#fff", height: "1px" }} />

      {/* Host QuizSync Section with Nested Subsections */}
      <Box sx={{ backgroundColor: "#f4f2ee", py: 5 }}>
        <Container maxWidth="md">
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, textAlign: "center" }}
          >
            <strong>Collect Real Time Responses from Students</strong>
          </Typography>

          {/* Classroom Image */}
          <Box sx={{ textAlign: "center", mt: 1, mb: { xs: 2, md: 3 } }}>
            <img
              src="/images/cr2.png"
              alt="Classroom"
              style={{ width: "80%", borderRadius: "8px" }}
            />
          </Box>

          {/* Collect Responses in Real Time */}
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                mt: 2, // Added top margin
              }}
            >
              {/* Circular Progress Component */}
              <Box sx={{ position: "relative", display: "inline-flex", mb: 3 }}>
                <CircularProgress
                  size={100}
                  variant="determinate"
                  value={(responses / totalAvatars) * 100}
                  sx={{
                    color: "#333",
                  }}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    component="div"
                    color="text.secondary"
                  >
                    {Math.round((responses / totalAvatars) * 100)}%
                  </Typography>
                </Box>
              </Box>

              {/* Avatars Grid */}
              <Grid container spacing={1} justifyContent="center">
                {avatars.map((avatar) => (
                  <Grid item xs={2} sm={1} key={avatar.id}>
                    <Box sx={{ position: "relative" }}>
                      <Avatar
                        src={`https://api.dicebear.com/9.x/adventurer/svg?seed=${avatar.id}`}
                        alt="Student Avatar"
                        sx={{ width: 40, height: 40 }}
                      />
                      {avatar.hasResponded && (
                        <BookmarkIcon
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                            color: "green",
                            fontSize: 16,
                          }}
                        />
                      )}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Typography
              variant="body1"
              sx={{ color: "#333", mt: 3, mb: 2, textAlign: "center" }}
            >
              Host real-time quizzes where teachers guide the students through
              questions in a simultaneous manner and Collect Responses in Real
              Time
            </Typography>
          </Box>
          {/* Divider */}
          <Divider sx={{ my: 3, backgroundColor: "#fff", height: "1px" }} />
          <Box>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, textAlign: "center" }}
            >
              <strong>Evaluate Response Distrubtion</strong>
            </Typography>

            <Box
              sx={{
                width: "70%", // Updated width to 80%
                p: 3,
                border: "1px solid #ddd",
                borderRadius: "10px",

                backgroundColor: "#fff",
                mt: 2,
                ml: "auto", // Align to center
                mr: "auto", // Align to center
              }}
            >
              {/* Question Header */}
              <Typography sx={{ fontWeight: "bold", color: "#333", mb: 2 }}>
                According to Newton's First Law, an object at rest will stay at
                rest unless acted upon by a force. What does this statement
                imply?
              </Typography>

              {/* Correct Answer */}
              <Box sx={{ mt: 3 }}>
                <Typography sx={{ fontWeight: "bold", color: "#333", mb: 1 }}>
                  Objects will change their motion only when a force is applied.
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    backgroundColor: "#f0f0f0",
                    borderRadius: "5px",
                    overflow: "hidden",
                    mb: 1,
                  }}
                >
                  <Box
                    sx={{
                      width: "75%", // 75% of the total responses
                      backgroundColor: "#4caf50", // Green color for correct answer
                      height: 20,
                    }}
                  />
                </Box>
                <Typography variant="body2" sx={{ color: "#666" }}>
                  22 responses (75%)
                </Typography>
              </Box>

              {/* Incorrect Options with Random Distribution */}
              {[
                {
                  text: "Objects need a constant force to keep moving.",
                  responses: 3, // Random number of responses for incorrect answer
                  percentage: "10%",
                },
                {
                  text: "Objects can only move when pushed.",
                  responses: 2, // Random number of responses for incorrect answer
                  percentage: "7%",
                },
                {
                  text: "Objects will eventually stop moving on their own.",
                  responses: 2, // Random number of responses for incorrect answer
                  percentage: "7%",
                },
              ].map((option, index) => (
                <Box key={index} sx={{ mt: 3 }}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: "#333",
                      mb: 1,
                    }}
                  >
                    {option.text}
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      backgroundColor: "#f0f0f0",
                      borderRadius: "5px",
                      overflow: "hidden",
                      mb: 1,
                    }}
                  >
                    <Box
                      sx={{
                        width: option.percentage, // Random percentage for incorrect answers
                        backgroundColor: "#f44336", // Red color for incorrect answers
                        height: 20,
                      }}
                    />
                  </Box>
                  <Typography variant="body2" sx={{ color: "#666" }}>
                    {option.responses} responses ({option.percentage})
                  </Typography>
                </Box>
              ))}
            </Box>
            <Typography
              variant="body1"
              sx={{ color: "#333", mt: 3, textAlign: "center" }}
            >
              By viewing the distribution of responses, educators can gain
              valuable insights into classroom understanding. This enables them
              to quickly identify areas where students may struggle and provides
              an opportunity to address misconceptions promptly.
            </Typography>
          </Box>

          {/* Divider */}
          <Divider sx={{ my: 3, backgroundColor: "#fff", height: "1px" }} />

          {/* Summary */}
          <Box>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, textAlign: "center" }}
            >
              <strong>Analyze Classroom and Student</strong>
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                mt: 1,
              }}
            >
              <img
                src="/images/classsummary.png"
                alt="Analytics Screenshot"
                style={{
                  width: "70%",
                  borderRadius: "8px",

                  border: "none",
                  marginBottom: "16px", // Added bottom margin
                }}
              />
            </Box>

            <Typography
              variant="body1"
              sx={{ color: "#333", mb: 2, textAlign: "center" }}
            >
              AI-generated summaries provide comprehensive insights into overall
              classroom dynamics as well as individual participant performance.
              They maintain records for improvements and offer valuable feedback
              to both students and teachers, aiding in effective teaching
              strategies.
            </Typography>
          </Box>

          {/* Divider */}
          <Divider sx={{ my: 3, backgroundColor: "#fff", height: "1px" }} />

          <Box sx={{ backgroundColor: "#f4f2ee", py: 5 }}>
            <Container maxWidth="md">
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, textAlign: "center" }}
              >
                <strong>Create Quizzes </strong>
              </Typography>

              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    mb: 4,
                    mt: 1,
                  }}
                >
                  {/* Logo and Description */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      maxWidth: "600px", // Control the maximum width
                      width: "100%", // Allow full width within maxWidth constraints
                    }}
                  >
                    {/* Logo */}
                    <Box sx={{ textAlign: "center", mb: { xs: 2, md: 3 } }}>
                      <img
                        src="/images/chatgptlogo.png"
                        alt="Chat GPT Logo"
                        style={{ width: "80px", borderRadius: "50%" }}
                      />
                    </Box>
                    {/* Description */}
                    <Box
                      sx={{
                        mt: { xs: 0, md: 0 },
                        ml: { xs: 0, md: 0 },
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      <Typography variant="body1" sx={{ color: "#333" }}>
                        Generate MCQs using Bloom's Taxonomy and leverage the
                        power of ChatGPT to create questions on any subject.
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mt: 2,
                          flexWrap: "wrap",
                        }}
                      >
                        {[
                          "Understand",
                          "Remember",
                          "Apply",
                          "Analyze",
                          "Evaluate",
                          "Create",
                        ].map((skill, index) => (
                          <Chip
                            key={index}
                            label={skill}
                            onClick={() => sliderRef.current.slickGoTo(index)}
                            variant={
                              currentSlide === index ? "filled" : "outlined"
                            }
                            color={
                              currentSlide === index ? "primary" : "default"
                            }
                            size="small"
                            sx={{
                              mx: 0.5,
                              my: 0.5,
                            }}
                          />
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Box>

                {/* Slider Section */}
                <Box sx={{ mt: 2, width: "100%" }}>
                  <Slider
                    ref={sliderRef}
                    dots={true}
                    arrows={true}
                    infinite={true}
                    speed={500}
                    slidesToShow={1}
                    slidesToScroll={1}
                    centerMode={true}
                    centerPadding="60px"
                    afterChange={(current) => setCurrentSlide(current)}
                    responsive={[
                      {
                        breakpoint: 768,
                        settings: {
                          centerPadding: "40px",
                        },
                      },
                      {
                        breakpoint: 480,
                        settings: {
                          centerPadding: "20px",
                        },
                      },
                    ]}
                  >
                    <div>
                      <img
                        src="/images/mcq_understand.png"
                        alt="Understand MCQ"
                        title="Understand MCQ"
                        style={{ width: "100%", borderRadius: "10px" }}
                      />
                      <Typography
                        variant="caption"
                        align="center"
                        sx={{ display: "block", color: "#333", mt: 1 }}
                      >
                        Generate MCQs to test how well students understand
                        concepts, such as summarizing or explaining in their own
                        words.
                      </Typography>
                    </div>
                    <div>
                      <img
                        src="/images/mcq_remember.png"
                        alt="Remember MCQ"
                        title="Remember MCQ"
                        style={{ width: "100%", borderRadius: "10px" }}
                      />
                      <Typography
                        variant="caption"
                        align="center"
                        sx={{ display: "block", color: "#333", mt: 1 }}
                      >
                        Generate MCQs focused on recalling facts, definitions,
                        or basic knowledge.
                      </Typography>
                    </div>
                    <div>
                      <img
                        src="/images/mcq_apply.png"
                        alt="Apply MCQ"
                        title="Apply MCQ"
                        style={{ width: "100%", borderRadius: "10px" }}
                      />
                      <Typography
                        variant="caption"
                        align="center"
                        sx={{ display: "block", color: "#333", mt: 1 }}
                      >
                        Generate MCQs that require students to apply learned
                        knowledge in new scenarios or problem-solving
                        situations.
                      </Typography>
                    </div>
                    <div>
                      <img
                        src="/images/mcq_analyze.png"
                        alt="Analyze MCQ"
                        title="Analyze MCQ"
                        style={{ width: "100%", borderRadius: "10px" }}
                      />
                      <Typography
                        variant="caption"
                        align="center"
                        sx={{ display: "block", color: "#333", mt: 1 }}
                      >
                        Generate MCQs that prompt students to break down
                        information into components, identify relationships, or
                        find patterns.
                      </Typography>
                    </div>
                    <div>
                      <img
                        src="/images/mcq_evaluate.png"
                        alt="Evaluate MCQ"
                        title="Evaluate MCQ"
                        style={{ width: "100%", borderRadius: "10px" }}
                      />
                      <Typography
                        variant="caption"
                        align="center"
                        sx={{ display: "block", color: "#333", mt: 1 }}
                      >
                        Generate MCQs that ask students to judge the value,
                        credibility, or logic of ideas and arguments.
                      </Typography>
                    </div>
                    <div>
                      <img
                        src="/images/mcq_create.png"
                        alt="Create MCQ"
                        title="Create MCQ"
                        style={{ width: "100%", borderRadius: "10px" }}
                      />
                      <Typography
                        variant="caption"
                        align="center"
                        sx={{ display: "block", color: "#333", mt: 1 }}
                      >
                        Generate MCQs that encourage students to create new
                        ideas, combine elements in novel ways, or develop
                        original solutions.
                      </Typography>
                    </div>
                  </Slider>
                </Box>
              </Box>
            </Container>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
              borderRadius: "8px",
              marginBottom: "20px",
              border: "1px dotted",
            }}
          >
            <UploadFileIcon
              sx={{ fontSize: 40, color: "#333", marginRight: 1 }}
            />
            <Typography
              variant="body1"
              sx={{
                color: "#333",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Upload your lecture notes to generate questions
            </Typography>
          </Box>

          {/* Divider */}
          <Divider sx={{ my: 3, backgroundColor: "#fff", height: "1px" }} />

          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, textAlign: "center" }}
          >
            <strong> 21+ Features </strong>
          </Typography>

          {/* Organize Hosted Quiz Sessions and Join the Quiz */}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
              gap: 4,
              mt: 2,
              alignItems: "center",
              justifyItems: "center",
              textAlign: "center",
            }}
          >
            {/* Organize Hosted Quiz Sessions Description */}

            <Box>
              <Typography
                variant="body1"
                sx={{
                  mb: 3,
                  color: "#333",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Organize hosted quiz sessions
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  gap: 1,
                  mt: 1,
                  mb: 2,
                }}
              >
                <Chip
                  label="Classroom: Grade 7"
                  variant="filled"
                  color="secondary"
                />
                <Chip
                  label="Subject: Mathematics"
                  variant="filled"
                  color="primary"
                />
              </Box>
            </Box>

            {/* Join the Quiz Session Description */}
            <Box>
              <Typography
                variant="body1"
                sx={{
                  mb: 3,
                  color: "#333",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Join quiz session using QR, link or code
              </Typography>

              <img
                src="/images/qrcode.png"
                alt="QR Code for session joining"
                style={{
                  width: "100px",
                  margin: "16px 0 8px 0",
                  borderRadius: "5%",
                }}
              />
            </Box>

            {/* Join the Quiz Session Description */}
            <Box>
              <Typography
                variant="body1"
                sx={{
                  mb: 3,
                  color: "#333",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                See detailed view for participants
              </Typography>

              <img
                src="/images/detailed_analysis.png"
                alt="Cropped view of session joining"
                style={{
                  width: "100%", // Set full width display
                  height: "95%", // Reduce the paper height
                  margin: "16px 0 8px 0",
                  borderRadius: "8px", // Rounded corners
                  objectFit: "cover", // Ensure the image is covered
                  objectPosition: "left", // Crop to show only the first 300 pixels
                  maxWidth: "400px", // Limit the width to display only the first 300 pixels
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Add halo effect
                  background: "#f7f7f7", // Give it a paper effect
                  padding: "1px", // Add padding for visual effect
                }}
              />
            </Box>

            {/* Join the Quiz Session Description */}
            <Box>
              <Typography
                variant="body1"
                sx={{
                  mb: 3,
                  color: "#333",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Get feedback from students on key concept misunderstandings
              </Typography>

              <FeedbackIcon
                sx={{
                  fontSize: 50,
                  width: "100px",
                  margin: "16px 0 8px 0",
                  borderRadius: "5%",
                }}
              />
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Leverage ChatGPT to Create Questions Using Bloom's Taxonomy */}

      {/* Divider */}
      <Divider sx={{ backgroundColor: "#fff", height: "1px" }} />

      {/* Pricing Section */}
      <Box sx={{ backgroundColor: "#e1bee7", py: 5, textAlign: "center" }}>
        <Container maxWidth="md">
          <Box
            sx={{
              p: 5,
              boxShadow: 3,
              borderRadius: "15px",
              backgroundColor: "#ffffff",
              maxWidth: "600px",
              margin: "0 auto",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mb: 3,
                fontWeight: "bold",
                color: "black",
                textAlign: "center",
              }}
            >
              $10 / month
            </Typography>

            <Divider sx={{ mb: 3 }} />

            <List
              sx={{
                textAlign: "center",
                display: "inline-block",
                color: "black",
                width: "70%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ListItem sx={{ justifyContent: "center" }}>
                <ListItemText
                  primary="• Unlimited quiz generation using ChatGPT."
                  sx={{ textAlign: "center" }}
                />
              </ListItem>
              <ListItem sx={{ justifyContent: "center" }}>
                <ListItemText
                  primary="• Unlimited classrooms and subjects."
                  sx={{ textAlign: "center" }}
                />
              </ListItem>
              <ListItem sx={{ justifyContent: "center" }}>
                <ListItemText
                  primary="• Unlimited students."
                  sx={{ textAlign: "center" }}
                />
              </ListItem>
              <ListItem sx={{ justifyContent: "center" }}>
                <ListItemText
                  primary="• Advanced analytics to provide a detailed view of students' and classrooms' understanding."
                  sx={{ textAlign: "center" }}
                />
              </ListItem>
              <ListItem sx={{ justifyContent: "center" }}>
                <ListItemText
                  primary="• Chat Support"
                  sx={{ textAlign: "center" }}
                />
              </ListItem>
            </List>

            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: rocketman,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={200}
              width={200}
            />

            <Button
              variant="contained"
              color="secondary"
              size="large"
              sx={{
                borderRadius: "25px",
                fontWeight: "bold",
                px: 4,
                py: 1.5,
                mt: 3,
              }}
              onClick={handleLogin}
            >
              Start Now
            </Button>

            <Typography
              variant="body1"
              sx={{
                mt: 1,
                fontWeight: "medium",
                color: "black",
                textAlign: "center",
              }}
            >
              Sign up today for a 3-month trial, no credit card required.
            </Typography>
          </Box>
        </Container>
      </Box>

      {/* Footer */}
      <Box
        sx={{
          py: 3,
          backgroundColor: "#333", // Footer specific background color
          color: "#fff",
          textAlign: "center",
        }}
      >
        <Typography variant="body2">
          © 2024 interactivelearning.one All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default LandingPage;

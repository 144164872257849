import React from "react";

const QuizSyncText = ({ children }) => {
  return (
    <span
      style={{
        fontFamily: '"Impact", "Arial", sans-serif',
        fontWeight: "bold",
      }}
    >
      {children}
    </span>
  );
};

export default QuizSyncText;

// JoinRouteComponent.js
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

const JoinRoute = ({ participantId, initiateQuizJoin }) => {
  const { quizCode } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (participantId) {
      initiateQuizJoin(quizCode);
      navigate("/");
    } else {
      // Set intended role to 'Student'
      localStorage.setItem("intendedRole", "Student");
      console.log("not logged in");
      // Redirect to login and include redirect path
      navigate(`/login?redirectTo=/join/${quizCode}`);
    }
  }, [quizCode, participantId, initiateQuizJoin, navigate]);

  return null;
};

export default JoinRoute;

import React, { useEffect, useState } from "react";
import { Drawer as MuiDrawer, Box, IconButton, Tooltip } from "@mui/material";
import QuizIcon from "@mui/icons-material/AddCircle";
import AssessmentIcon from "@mui/icons-material/Assessment";
import PeopleIcon from "@mui/icons-material/People";

import SessionsList from "./SessionsList";

const Drawer = ({
  userInfo,
  socket,
  drawerOpen,
  toggleDrawer,
  participantId,
  setSelectedSession,
  setMode,
  mode,
}) => {
  const [activeIcon, setActiveIcon] = useState("dashboard");
  const [pastSessions, setPastSessions] = useState([]);
  const [loadingSession, setLoadingSession] = useState(false);

  const handleNavigation = (mode) => {
    setMode(mode);
    setActiveIcon(mode);
  };

  const handleSessionClick = (sessionId) => {
    setLoadingSession(true);
    socket.emit("getSessionDetails", { sessionId });
  };

  const formatToLocalTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  useEffect(() => {
    socket.on("sessionsByHostSuccess", (sessions) => {
      setPastSessions(sessions);
    });

    return () => {
      socket.off("sessionsByHostSuccess");
    };
  }, [socket, participantId]);

  useEffect(() => {
    socket.on("sessionDetailsSuccess", (sessionDetails) => {
      setLoadingSession(false);
      setSelectedSession(sessionDetails);
      setMode("sessionDetails");
    });

    return () => {
      socket.off("sessionDetailsSuccess");
    };
  }, [socket, setSelectedSession, setMode]);

  // List of modes where the drawer should not be rendered
  const hiddenModes = ["participantActive", "hostActive"];

  // If the current mode is in the hiddenModes list, do not render the drawer
  if (hiddenModes.includes(mode) || userInfo.role === "Student") {
    return null;
  }

  if (userInfo.role === "Teacher") {
    return (
      <MuiDrawer
        anchor="left"
        variant="permanent"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            width: activeIcon === "pastSessions" ? 310 : 50,
            backgroundColor: "background.paper",
            color: "text.primary",
            mt: 8,
            display: "flex",
            flexDirection: "row",
            transition: "width 0.3s",
          },
        }}
      >
        <Box sx={{ width: 50, borderRight: 1, borderColor: "divider", pt: 2 }}>
          <Tooltip title="Quizzes List">
            <IconButton
              onClick={() => handleNavigation("quizzesList")}
              sx={{
                color:
                  activeIcon === "quizzesList" ? "primary.main" : "inherit",
              }}
            >
              <QuizIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Sessions List">
            <IconButton
              onClick={() => handleNavigation("sessionsList")}
              sx={{
                color:
                  activeIcon === "sessionsList" ? "primary.main" : "inherit",
              }}
            >
              <AssessmentIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Participant List">
            <IconButton
              onClick={() => handleNavigation("participantList")}
              sx={{
                color:
                  activeIcon === "participantList" ? "primary.main" : "inherit",
              }}
            >
              <PeopleIcon />
            </IconButton>
          </Tooltip>
        </Box>

        {activeIcon === "pastSessions" && (
          <Box sx={{ width: 260, p: 2 }}>
            <SessionsList
              pastSessions={pastSessions}
              formatToLocalTime={formatToLocalTime}
              handleSessionClick={handleSessionClick}
            />
          </Box>
        )}
      </MuiDrawer>
    );
  }

  return null;
};

export default Drawer;

import React from "react";
import {
  Box,
  TextField,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CardContent,
  Typography,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

const EditableQuestion = ({
  question,
  questionIndex,
  handleQuestionChange,
  handleOptionChange,
  handleCorrectAnswerChange,
  handleExplanationChange,
  handleDeleteQuestion,
  handleSkillTagChange,
  handleCognitiveLevelChange,
  handleMoveQuestionUp,
  handleMoveQuestionDown,
  handleKeyConceptChange,
  editableQuestions,
  onClose,
  themeMode,
}) => {
  return (
    <Accordion
      expanded
      sx={{
        marginBottom: 2,
        width: "100%",
        backgroundColor: themeMode === "light" ? "#ffffff" : "#2c2c2c",
        borderRadius: "8px",
        boxShadow:
          themeMode === "light"
            ? "0 1px 4px rgba(0, 0, 0, 0.1)"
            : "0 1px 4px rgba(0, 0, 0, 0.2)",
        border: "1px solid transparent",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`question-${questionIndex}-content`}
        id={`question-${questionIndex}-header`}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          {/* Typography to wrap text and allow space for icons */}
          <Typography
            sx={{
              flexGrow: 1,
              whiteSpace: "normal",
              maxWidth: "80%",
            }}
          >
            {`Question ${questionIndex + 1}: ${question.question}`}
          </Typography>
          {/* Icon buttons */}
          <Box>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                handleDeleteQuestion(questionIndex);
              }}
              size="small"
              color="error"
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                handleMoveQuestionUp(question.contentId);
              }}
              size="small"
              disabled={questionIndex === 0}
            >
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                handleMoveQuestionDown(question.contentId);
              }}
              size="small"
              disabled={questionIndex === editableQuestions.length - 1}
            >
              <ArrowDownwardIcon />
            </IconButton>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                onClose();
              }}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <CardContent>
          <TextField
            label={`Question ${questionIndex + 1}`}
            value={question.question}
            onChange={(event) => handleQuestionChange(questionIndex, event)}
            fullWidth
            margin="normal"
          />
          {question.options.map((option, optionIndex) => (
            <TextField
              key={`question-${questionIndex}-option-${optionIndex}`}
              label={`Option ${optionIndex + 1}`}
              value={option}
              onChange={(event) =>
                handleOptionChange(questionIndex, optionIndex, event)
              }
              fullWidth
              margin="normal"
            />
          ))}
          <TextField
            label="Correct Answer"
            value={question.correctAnswer}
            onChange={(event) =>
              handleCorrectAnswerChange(questionIndex, event)
            }
            fullWidth
            margin="normal"
          />
          <TextField
            label="Explanation"
            value={question.explanation}
            onChange={(event) => handleExplanationChange(questionIndex, event)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Skill Tag"
            value={question.skill_tag}
            onChange={(event) => handleSkillTagChange(questionIndex, event)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Cognitive Level"
            value={question.cognitive_level}
            onChange={(event) =>
              handleCognitiveLevelChange(questionIndex, event)
            }
            fullWidth
            margin="normal"
          />
          <TextField
            label="Key Concept"
            value={question.key_concept}
            onChange={(event) => handleKeyConceptChange(questionIndex, event)}
            fullWidth
            margin="normal"
          />
        </CardContent>
      </AccordionDetails>
    </Accordion>
  );
};

export default EditableQuestion;

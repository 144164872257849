import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Divider,
  Chip,
} from "@mui/material";
import { format } from "date-fns";

const SessionsList = ({
  socket,
  participantId,
  setMode,
  setSelectedSession,
  themeMode,
  mixpanel,
}) => {
  const [pastSessions, setPastSessions] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");

  useEffect(() => {
    // Track the page view with Mixpanel
    mixpanel.track("Viewed Sessions List");

    const fetchSessionsByHost = async () => {
      socket.emit("getSessionsByHost", { participantId });
    };

    socket.on("sessionsByHostSuccess", (sessions) => {
      // Sort sessions from latest to oldest
      const sortedSessions = sessions.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at),
      );
      setPastSessions(sortedSessions);
    });

    socket.on("socketError", (errorMessage) => {
      console.error("Socket error:", errorMessage);
    });

    // Listen for session details response
    socket.on("sessionDetailsSuccess", (sessionData) => {
      setSelectedSession(sessionData);
      setMode("sessionDetails");
    });

    fetchSessionsByHost();

    return () => {
      socket.off("sessionsByHostSuccess");
      socket.off("socketError");
      socket.off("sessionDetailsSuccess"); // Clean up listener
    };
  }, [socket, participantId, setMode, setSelectedSession, mixpanel]);

  const handleSessionClick = useCallback(
    (sessionId) => {
      console.log("emitting session detail for", sessionId);

      // Track the session click with Mixpanel
      mixpanel.track("Clicked Session", { sessionId });

      socket.emit("getSessionDetails", { sessionId }); // Request session details
    },
    [socket, mixpanel],
  );

  const handleFilterClick = useCallback(
    (filter) => {
      const newFilter = selectedFilter === filter ? "" : filter;
      setSelectedFilter(newFilter);

      // Track the filter selection with Mixpanel
      mixpanel.track("Selected Filter", { filter: newFilter || "All" });
    },
    [selectedFilter, mixpanel],
  );

  const formatToLocalTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  // Filtering sessions based on selected classroom and subject combination
  const filteredSessions = useMemo(() => {
    return pastSessions.filter((session) => {
      const filterString = `${session.classroom || "Untitled Classroom"} - ${
        session.subject || "Untitled Subject"
      }`;
      return selectedFilter === "" || filterString === selectedFilter;
    });
  }, [pastSessions, selectedFilter]);

  // Extract unique classroom-subject combinations for the chip options
  const uniqueFilters = useMemo(
    () => [
      ...new Set(
        pastSessions.map(
          (session) =>
            `${session.classroom || "Untitled Classroom"} - ${
              session.subject || "Untitled Subject"
            }`,
        ),
      ),
    ],
    [pastSessions],
  );

  // Group sessions by day of the month
  const sessionsByDate = useMemo(() => {
    const sessionsMap = {};
    filteredSessions.forEach((session) => {
      const sessionDate = format(new Date(session.created_at), "yyyy-MM-dd");
      if (!sessionsMap[sessionDate]) {
        sessionsMap[sessionDate] = [];
      }
      sessionsMap[sessionDate].push(session);
    });
    return sessionsMap;
  }, [filteredSessions]);

  // Get all days of the current month that have sessions to display in the timeline view
  const daysInMonthWithSessions = useMemo(() => {
    return Object.keys(sessionsByDate).map((date) => new Date(date));
  }, [sessionsByDate]);

  return (
    <Box
      sx={{
        width: "90%",
        marginLeft: "50px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="h4" sx={{ mb: 2 }}>
        Sessions
      </Typography>
      <Divider sx={{ mb: 2, width: "100%" }} />

      {/* Filter Chips for Classroom and Subject Combinations */}
      <Box
        sx={{
          display: "flex",
          gap: 1,
          width: "100%",
          flexWrap: "wrap",
          justifyContent: "center",
          mb: 4,
        }}
      >
        {uniqueFilters.map((filter) => (
          <Chip
            key={filter}
            label={filter}
            clickable
            color={selectedFilter === filter ? "primary" : "default"}
            onClick={() => handleFilterClick(filter)}
          />
        ))}
      </Box>

      {/* Timeline View of Sessions */}
      <Box sx={{ width: "100%", mb: 4 }}>
        {daysInMonthWithSessions.map((day) => {
          const dayString = format(day, "yyyy-MM-dd");
          const sessions = sessionsByDate[dayString] || [];

          return (
            <Box key={dayString} sx={{ mb: 4 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                {format(day, "EEEE, MMM d")}
              </Typography>
              <Grid container spacing={2}>
                {sessions.map((session) => {
                  // Parse the summary JSON to extract overallPerformance
                  let overallPerformance = "N/A";
                  try {
                    const summary = JSON.parse(session.summary);
                    overallPerformance = summary?.overallPerformance || "N/A";
                  } catch (error) {
                    console.error("Error parsing session summary: ", error);
                  }

                  return (
                    <Grid item xs={12} key={session.sessionId}>
                      <Card
                        sx={{
                          width: "100%",
                          mb: 2,
                          cursor: "pointer",
                          backgroundColor:
                            themeMode === "light" ? "#ffffff" : "#2c2c2c",
                          borderRadius: "8px",
                          boxShadow:
                            themeMode === "light"
                              ? "0 1px 4px rgba(0, 0, 0, 0.1)"
                              : "0 1px 4px rgba(0, 0, 0, 0.2)",
                          transition: "all 0.3s ease-in-out",
                          border: "1px solid transparent",
                          "&:hover": {
                            borderColor: "#5e52f7",
                            boxShadow:
                              themeMode === "light"
                                ? "0 2px 6px rgba(0, 0, 0, 0.1)"
                                : "0 2px 6px rgba(0, 0, 0, 0.2)",
                          },
                        }}
                        onClick={() => handleSessionClick(session.sessionId)}
                      >
                        <CardContent>
                          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                            {session.quizTitle || "Untitled Session"}
                          </Typography>
                          <Typography variant="body2" sx={{ mt: 1, mb: 1 }}>
                            Overall Performance: {overallPerformance}
                          </Typography>
                          <Box display="flex" gap={1}>
                            <Chip
                              label={session.classroom || "Untitled Classroom"}
                              variant="filled"
                              color="primary"
                            />
                            <Chip
                              label={session.subject || "Untitled Subject"}
                              variant="filled"
                              color="secondary"
                            />
                          </Box>
                          <Typography variant="subtitle2" sx={{ mt: 1 }}>
                            Hosted At: {formatToLocalTime(session.created_at)}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default SessionsList;

import React, { useState, useCallback, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Chip,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddIcon from "@mui/icons-material/Add";

const GenerateMCQ = ({
  socket,
  quizId,
  editableQuestions,
  setIsAddingQuestion,
  setEditableQuestions,
  setQuizId,
  isAddingQuestion,
  themeMode,
  mixpanel,
}) => {
  const [files, setFiles] = useState([]);
  const [extractingText, setExtractingText] = useState(false);
  const [additionalPrompt, setAdditionalPrompt] = useState("");
  const [numberOfQuestions, setNumberOfQuestions] = useState(1);
  const [cognitiveLevel, setCognitiveLevel] = useState("LOTS");
  const [skillTag, setSkillTag] = useState("understand");
  const [includeCurrentQuestions, setIncludeCurrentQuestions] = useState(true);

  useEffect(() => {
    mixpanel.track("GenerateMCQ component loaded");
  }, [mixpanel]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles(acceptedFiles);
      if (acceptedFiles.length > 0) {
        mixpanel.track("File uploaded", {
          fileName: acceptedFiles[0].name,
        });
      }
    },
    [mixpanel],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  const handleRemoveFile = () => {
    setFiles([]);
  };

  const handleCognitiveLevelChange = (level) => {
    setCognitiveLevel(level);
    setSkillTag(level === "LOTS" ? "understand" : "analyze");
    mixpanel.track("Cognitive level changed", {
      cognitiveLevel: level,
    });
  };

  const handleSkillTagChange = (tag) => {
    setSkillTag(tag);
    mixpanel.track("Skill tag changed", {
      skillTag: tag,
    });
  };

  const handleIncludeCurrentQuestionsChange = (e) => {
    setIncludeCurrentQuestions(e.target.checked);
    mixpanel.track("Include current questions toggled", {
      includeCurrentQuestions: e.target.checked,
    });
  };

  const requestGenerateQuestions = useCallback(() => {
    setIsAddingQuestion(true);
    const payload = {
      quizId,
      additionalPrompt,
      currentQuestions: includeCurrentQuestions ? editableQuestions : [],
      numberOfQuestions,
      cognitiveLevel,
      skillTag,
    };
    if (files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        const fileBuffer = e.target.result;
        socket.emit("generateQuestions", {
          ...payload,
          fileBuffer,
        });
      };
      reader.readAsArrayBuffer(file);
    } else {
      socket.emit("generateQuestions", payload);
    }
  }, [
    socket,
    quizId,
    additionalPrompt,
    editableQuestions,
    numberOfQuestions,
    cognitiveLevel,
    skillTag,
    files,
    includeCurrentQuestions,
    setIsAddingQuestion,
  ]);

  const handleButtonClick = () => {
    mixpanel.track("Generate questions clicked", {
      additionalPrompt,
      numberOfQuestions,
      cognitiveLevel,
      skillTag,
      includeCurrentQuestions,
      fileUploaded: files.length > 0,
    });
    requestGenerateQuestions();
  };

  useEffect(() => {
    socket.on("questionsGeneratedFromFileStart", () => {
      setIsAddingQuestion(true);
      mixpanel.track("Questions generation started");
    });

    socket.on(
      "questionsGeneratedFromFileSuccess",
      ({ quizId: receivedQuizId, questions }) => {
        setEditableQuestions((prevQuestions) => [
          ...prevQuestions,
          ...questions,
        ]);
        setIsAddingQuestion(false);
        if (!quizId) {
          setQuizId(receivedQuizId);
        }
        mixpanel.track("Questions generated successfully", {
          numberOfQuestions: questions.length,
        });
      },
    );

    return () => {
      socket.off("questionsGeneratedFromFileSuccess");
      socket.off("questionsGeneratedFromFileStart");
    };
  }, [
    socket,
    quizId,
    setEditableQuestions,
    setIsAddingQuestion,
    setQuizId,
    mixpanel,
  ]);

  return (
    <Card
      sx={{
        width: "100%",
        backgroundColor: themeMode === "light" ? "#ffffff" : "#2c2c2c",
        borderRadius: "8px",
        marginBottom: 2,
        boxShadow:
          themeMode === "light"
            ? "0 1px 4px rgba(0, 0, 0, 0.1)"
            : "0 1px 4px rgba(0, 0, 0, 0.2)",
        border: "1px solid transparent",
      }}
    >
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
          <Typography variant="h5">Generate MCQ</Typography>
        </Box>
        <TextField
          label="Enter Prompt Here"
          value={additionalPrompt}
          onChange={(event) => setAdditionalPrompt(event.target.value)}
          fullWidth
          margin="normal"
        />

        <TextField
          type="number"
          label="Number of Questions"
          value={numberOfQuestions}
          onChange={(e) => setNumberOfQuestions(Number(e.target.value))}
          fullWidth
          margin="normal"
        />

        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Cognitive Level
          </Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Chip
              label="Lower Order Thinking"
              color={cognitiveLevel === "LOTS" ? "primary" : "default"}
              onClick={() => handleCognitiveLevelChange("LOTS")}
              variant={cognitiveLevel === "LOTS" ? "filled" : "outlined"}
            />
            <Chip
              label="Higher Order Thinking"
              color={cognitiveLevel === "HOTS" ? "primary" : "default"}
              onClick={() => handleCognitiveLevelChange("HOTS")}
              variant={cognitiveLevel === "HOTS" ? "filled" : "outlined"}
            />
          </Box>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Skill Tag
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {cognitiveLevel === "LOTS" ? (
              <>
                <Chip
                  label="Understand"
                  color={skillTag === "understand" ? "primary" : "default"}
                  onClick={() => handleSkillTagChange("understand")}
                  variant={skillTag === "understand" ? "filled" : "outlined"}
                />
                <Chip
                  label="Remember"
                  color={skillTag === "remember" ? "primary" : "default"}
                  onClick={() => handleSkillTagChange("remember")}
                  variant={skillTag === "remember" ? "filled" : "outlined"}
                />
                <Chip
                  label="Apply"
                  color={skillTag === "apply" ? "primary" : "default"}
                  onClick={() => handleSkillTagChange("apply")}
                  variant={skillTag === "apply" ? "filled" : "outlined"}
                />
              </>
            ) : (
              <>
                <Chip
                  label="Analyze"
                  color={skillTag === "analyze" ? "primary" : "default"}
                  onClick={() => handleSkillTagChange("analyze")}
                  variant={skillTag === "analyze" ? "filled" : "outlined"}
                />
                <Chip
                  label="Evaluate"
                  color={skillTag === "evaluate" ? "primary" : "default"}
                  onClick={() => handleSkillTagChange("evaluate")}
                  variant={skillTag === "evaluate" ? "filled" : "outlined"}
                />
                <Chip
                  label="Create"
                  color={skillTag === "create" ? "primary" : "default"}
                  onClick={() => handleSkillTagChange("create")}
                  variant={skillTag === "create" ? "filled" : "outlined"}
                />
              </>
            )}
          </Box>
        </Box>

        <FormControlLabel
          control={
            <Switch
              checked={includeCurrentQuestions}
              onChange={handleIncludeCurrentQuestionsChange}
            />
          }
          label="Include Current Questions"
        />

        <Box
          {...getRootProps()}
          sx={{
            border: "2px dashed gray",
            p: 2,
            textAlign: "center",
            cursor: "pointer",
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography>Drop the PDF here ...</Typography>
          ) : (
            <Typography>
              Drag and drop a PDF file here, or click to select a file
            </Typography>
          )}
          <CloudUploadIcon sx={{ fontSize: 40, mt: 1 }} />
        </Box>

        {files.length > 0 && (
          <List>
            {files.map((file) => (
              <ListItem
                key={file.path}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={handleRemoveFile}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemText
                  primary={file.name}
                  secondary={`${file.size} bytes`}
                />
              </ListItem>
            ))}
          </List>
        )}

        {(extractingText || isAddingQuestion) && (
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <CircularProgress size={24} />
            <Typography sx={{ ml: 2 }}>
              {extractingText
                ? "Extracting text from PDF..."
                : "Generating questions..."}
            </Typography>
          </Box>
        )}

        <Box display="flex" justifyContent="center" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleButtonClick}
            title="Generate question using ChatGPT"
            sx={{
              mt: 2,
              width: 64,
              height: 64,
              borderRadius: "50%",
              minWidth: 0,
              padding: 0,
            }}
          >
            <img
              src="images/chatgptlogo.png"
              alt="ChatGPT Logo"
              style={{
                width: 32,
                height: 32,
                borderRadius: "50%",
              }}
            />
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default GenerateMCQ;

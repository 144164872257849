import React, { useEffect } from "react";
import { motion } from "framer-motion";

import logo from "../logo.svg"; // Importing logo from the src folder
import QuizSyncText from "./QuizSyncText"; // Adjusting the path based on your project structure
import { useNavigate, useLocation } from "react-router-dom";

const Login = ({ onLoginSuccess }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Set intended role to 'Teacher' if not already set
    const intendedRole = localStorage.getItem("intendedRole");
    if (!intendedRole) {
      localStorage.setItem("intendedRole", "Teacher");
    }

    const loadScript = () => {
      const script = document.createElement("script");
      script.id = "otpless-sdk";
      script.type = "text/javascript";
      script.src = "https://otpless.com/v2/auth.js";
      script.setAttribute("data-appid", "TGINV2YDR49AT6CA7RVM");
      document.head.appendChild(script);

      script.onload = () => {
        window.otpless = (otplessUser) => {
          const userInfo = {
            name: otplessUser.name,
            email: otplessUser.email,
          };
          localStorage.setItem("authToken", otplessUser.token);
          onLoginSuccess(userInfo);

          // Extract 'redirectTo' from query parameters
          const params = new URLSearchParams(location.search);
          const redirectTo = params.get("redirectTo");
          if (redirectTo) {
            navigate(redirectTo);
          } else {
            navigate("/"); // Or any default route
          }
        };
      };
    };

    loadScript();
  }, [onLoginSuccess]);

  const containerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100vw",
    height: "100vh",
    backgroundColor: "#333", // Changed to dark grey
    color: "white", // Font color to contrast the dark grey background
  };

  const logoStyle = {
    width: "80px", // Slightly smaller
    marginBottom: "16px",
  };

  const textStyle = {
    marginBottom: "32px", // Add space between text and the login form
    fontSize: "24px",
  };

  const loginPageStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={containerStyle}
    >
      <motion.img
        src={logo}
        alt="QuizSync Logo"
        style={logoStyle}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.2 }}
      />
      <motion.div
        style={textStyle}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.4 }}
      >
        <QuizSyncText>QuizSync</QuizSyncText>
      </motion.div>
      <motion.div
        id="otpless-login-page"
        style={loginPageStyle}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.6 }}
      ></motion.div>
    </motion.div>
  );
};

export default Login;

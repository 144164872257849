import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  LinearProgress,
  Avatar,
  Paper,
  useTheme,
  Chip,
  Drawer,
  IconButton,
  Tabs,
  Tab,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const SessionDetails = ({
  loadingSession,
  selectedSession,
  themeMode,
  pId,
  mixpanel,
}) => {
  const [createdAt, setCreatedAt] = useState(null);
  const [title, setTitle] = useState(null);
  const [classroom, setClassroom] = useState(null);
  const [subject, setSubject] = useState(null);
  const [summary, setSummary] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    if (selectedSession) {
      console.log(selectedSession);
      const firstSession = selectedSession.sessionDetails[0];
      if (firstSession) {
        setCreatedAt(firstSession.created_at);
        setTitle(firstSession.quizTitle);
        setClassroom(firstSession.classroom);
        setSubject(firstSession.subject);
        const parsedSummary = JSON.parse(firstSession.summary);
        setSummary(parsedSummary); // Set summary as parsed JSON
      }
      // Track when the session details are loaded
      mixpanel.track("Session Details Loaded", {
        sessionId: selectedSession.id,
        title: firstSession.quizTitle,
      });
    }
  }, [selectedSession, mixpanel]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  if (loadingSession) {
    return <CircularProgress />;
  }

  if (!selectedSession) {
    return null;
  }

  const { sessionDetails, responses } = selectedSession;

  // Compute overall rating average
  const overallRatingAverage = (() => {
    if (!selectedSession || !selectedSession.keyconceptNotUnderstood) {
      return null;
    }

    const ratings = selectedSession.keyconceptNotUnderstood
      .map((item) => parseFloat(item.rating))
      .filter((rating) => !isNaN(rating));

    if (ratings.length === 0) {
      return null;
    }

    const sum = ratings.reduce((acc, curr) => acc + curr, 0);
    const average = sum / ratings.length;
    return average.toFixed(2);
  })();

  // Compute key concepts not understood distribution
  const keyConceptsDistribution = (() => {
    if (!selectedSession || !selectedSession.keyconceptNotUnderstood) {
      return {};
    }

    const conceptCounts = {};

    selectedSession.keyconceptNotUnderstood.forEach((item) => {
      const concepts = JSON.parse(item.key_concept_not_understood || "[]");
      concepts.forEach((concept) => {
        if (conceptCounts[concept]) {
          conceptCounts[concept]++;
        } else {
          conceptCounts[concept] = 1;
        }
      });
    });

    return conceptCounts;
  })();

  const calculateOptionCounts = (sessionDetails, responses) => {
    return sessionDetails.map((session) => {
      const {
        data: { options },
        contentId,
      } = session;

      const optionCounts = options.reduce((counts, option) => {
        counts[option] = 0;
        return counts;
      }, {});

      responses.forEach(({ response, contentId: responseContentId }) => {
        if (
          responseContentId === contentId &&
          optionCounts.hasOwnProperty(response)
        ) {
          optionCounts[response]++;
        }
      });

      return {
        ...session,
        optionCounts,
      };
    });
  };

  const calculateParticipantPerformance = () => {
    const performance = {};

    responses.forEach(
      ({
        participantId,
        participantName,
        participantAvatar,
        response,
        contentId,
        participantSummary,
      }) => {
        if (!performance[participantId]) {
          performance[participantId] = {
            participantId,
            participantName,
            participantAvatar,
            correct: 0,
            incorrect: 0,
            total: 0,
            responses: [],
            participantSummary: JSON.parse(participantSummary),
          };
        }

        const question = sessionDetails.find(
          (session) => session.contentId === contentId,
        );

        if (question) {
          performance[participantId].total++;
          const isCorrect = response === question.data.correctAnswer;

          performance[participantId].responses.push({
            question: question.data.question,
            keyConcept: question.key_concept,
            response,
            isCorrect,
            correctAnswer: question.data.correctAnswer,
          });

          if (isCorrect) {
            performance[participantId].correct++;
          } else {
            performance[participantId].incorrect++;
          }
        }
      },
    );

    return Object.values(performance);
  };

  const sessionsWithOptionCounts = calculateOptionCounts(
    sessionDetails,
    responses,
  );

  const getOptionProgressBarColor = (isCorrect) => {
    return isCorrect ? theme.palette.success.main : theme.palette.error.main;
  };

  const formatToLocalTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const renderUserSummary = () => {
    if (!summary) {
      return null;
    }

    return (
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          marginBottom: 3,
          backgroundColor: themeMode === "light" ? "#ffffff" : "#2c2c2c",
          borderRadius: "8px",
          boxShadow:
            themeMode === "light"
              ? "0 1px 4px rgba(0, 0, 0, 0.1)"
              : "0 1px 4px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Typography variant="h5" gutterBottom>
          Summary
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Overall Performance:</strong> {summary.overallPerformance}
        </Typography>
        <Typography variant="body1">
          <strong>Areas for Improvement:</strong>
          <ul>
            {summary.areasForImprovement.map((area, index) => (
              <li key={index}>{area}</li>
            ))}
          </ul>
        </Typography>
      </Paper>
    );
  };

  const renderAggregatedSummary = () => {
    const totalQuestions = sessionDetails.length;
    const totalParticipants = new Set(responses.map((r) => r.participantId))
      .size;

    return (
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          marginBottom: 3,
          backgroundColor: themeMode === "light" ? "#ffffff" : "#2c2c2c",
          borderRadius: "8px",
          boxShadow:
            themeMode === "light"
              ? "0 1px 4px rgba(0, 0, 0, 0.1)"
              : "0 1px 4px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
        <Box display="flex" gap={1} sx={{ marginBottom: 2 }}>
          <Chip
            key={classroom || "untitled-classroom"}
            label={classroom || "Untitled"}
            color="primary"
            variant="filled"
          />
          <Chip
            key={subject || "untitled-subject"}
            label={subject || "Untitled"}
            color="secondary"
            variant="filled"
          />
        </Box>
        <Typography variant="body1">
          <strong>Hosted At:</strong> {formatToLocalTime(createdAt)}
        </Typography>
        <Typography variant="body1">
          <strong>Total Questions:</strong> {totalQuestions}
        </Typography>
        <Typography variant="body1">
          <strong>Total Participants:</strong> {totalParticipants}
        </Typography>
      </Paper>
    );
  };

  const renderQuestions = () => {
    return (
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          marginBottom: 3,
          backgroundColor: themeMode === "light" ? "#ffffff" : "#2c2c2c",
          borderRadius: "8px",
          boxShadow:
            themeMode === "light"
              ? "0 1px 4px rgba(0, 0, 0, 0.1)"
              : "0 1px 4px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
          Session Details
        </Typography>

        {sessionsWithOptionCounts.map((session, index) => {
          const totalResponses = Object.values(session.optionCounts).reduce(
            (a, b) => a + b,
            0,
          );

          return (
            <Box
              key={index}
              sx={{
                marginBottom: 4,
                padding: 2,
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: 2,
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
              >
                {`Question ${index + 1}`}
              </Typography>
              <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
                <Chip
                  label={session.cognitive_level}
                  size="small"
                  variant="outlined"
                />
                <Chip
                  label={session.skill_tag}
                  size="small"
                  variant="outlined"
                />
                <Chip
                  label={session.key_concept}
                  size="small"
                  variant="outlined"
                />
              </Box>
              <Typography
                variant="body1"
                sx={{ marginY: 2, color: theme.palette.text.secondary }}
              >
                {session.data.question}
              </Typography>

              {session.data.options.map((option, optionIndex) => {
                const count = session.optionCounts[option];
                const percentage = totalResponses
                  ? (count / totalResponses) * 100
                  : 0;
                const isCorrect = option === session.data.correctAnswer;

                return (
                  <Box
                    key={optionIndex}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 2,
                      gap: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        marginRight: 2,
                        flexBasis: "25%",
                        fontWeight: "bold",
                        wordBreak: "break-word",
                        color: isCorrect
                          ? theme.palette.success.main
                          : theme.palette.text.primary,
                      }}
                      variant="body2"
                    >
                      {option}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={percentage}
                      sx={{
                        flexGrow: 1,
                        height: 10,
                        borderRadius: 5,
                        marginRight: 2,
                        backgroundColor: theme.palette.action.hover,
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: getOptionProgressBarColor(isCorrect),
                        },
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        minWidth: "10%",
                        color: theme.palette.text.secondary,
                      }}
                    >
                      {`${percentage.toFixed(1)}%`}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        minWidth: "10%",
                        color: theme.palette.text.secondary,
                      }}
                    >
                      {`${count} responses`}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          );
        })}
      </Paper>
    );
  };

  // Updated function to include all key concepts from sessionDetails
  const getAllUniqueKeyConcepts = (selectedSession) => {
    // Get all key concepts and their cognitive levels
    const allConcepts = selectedSession.sessionDetails.map((session) => ({
      name: session.key_concept,
      type: session.cognitive_level, // Use cognitive_level for LOTS/HOTS
      skill_tag: session.skill_tag,
    }));

    // Remove duplicates based on the concept name
    const uniqueConcepts = allConcepts.reduce((acc, curr) => {
      if (!acc.some((concept) => concept.name === curr.name)) {
        acc.push(curr);
      }
      return acc;
    }, []);

    return uniqueConcepts;
  };

  const renderLegend = (tabIndex) => {
    if (tabIndex === 0) {
      // Combined View legend
      return (
        <Paper
          elevation={3}
          sx={{
            padding: 3,
            marginTop: 2,
            backgroundColor: themeMode === "light" ? "#f5f5f5" : "#2c2c2c",
            borderRadius: 2,
            boxShadow:
              themeMode === "light"
                ? "0 1px 4px rgba(0, 0, 0, 0.1)"
                : "0 1px 4px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
            Legend
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            gap={2}
            sx={{ marginBottom: 1 }}
          >
            <CheckCircleIcon color="success" />
            <Typography variant="body2">
              <strong>Correct Understanding:</strong> All related questions were
              answered correctly, and no issues reported in feedback.
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            gap={2}
            sx={{ marginBottom: 1 }}
          >
            <CancelIcon color="error" />
            <Typography variant="body2">
              <strong>Misunderstood:</strong> Questions related to the concept
              were answered incorrectly, and the participant also reported
              misunderstanding.
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            gap={2}
            sx={{ marginBottom: 1 }}
          >
            <ReportProblemIcon color="warning" />
            <Typography variant="body2">
              <strong>Contradiction:</strong> Questions were answered correctly,
              but the participant reported not understanding the concept in
              feedback.
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            gap={2}
            sx={{ marginBottom: 1 }}
          >
            <CancelIcon color="info" />
            <Typography variant="body2">
              <strong>Overconfidence:</strong> Participant reported
              understanding the concept, but their responses were incorrect.
            </Typography>
          </Box>
        </Paper>
      );
    } else if (tabIndex === 1) {
      // Answer Correctness legend
      return (
        <Paper
          elevation={3}
          sx={{
            padding: 3,
            marginTop: 2,
            backgroundColor: themeMode === "light" ? "#f5f5f5" : "#2c2c2c",
            borderRadius: 2,
            boxShadow:
              themeMode === "light"
                ? "0 1px 4px rgba(0, 0, 0, 0.1)"
                : "0 1px 4px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
            Legend
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            gap={2}
            sx={{ marginBottom: 1 }}
          >
            <CheckCircleIcon color="success" />
            <Typography variant="body2">
              <strong>Correct:</strong> All related questions were answered
              correctly.
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            gap={2}
            sx={{ marginBottom: 1 }}
          >
            <CancelIcon color="error" />
            <Typography variant="body2">
              <strong>Incorrect:</strong> Some or all related questions were
              answered incorrectly.
            </Typography>
          </Box>
        </Paper>
      );
    } else if (tabIndex === 2) {
      // Feedback Misunderstandings legend
      return (
        <Paper
          elevation={3}
          sx={{
            padding: 3,
            marginTop: 2,
            backgroundColor: themeMode === "light" ? "#f5f5f5" : "#2c2c2c",
            borderRadius: 2,
            boxShadow:
              themeMode === "light"
                ? "0 1px 4px rgba(0, 0, 0, 0.1)"
                : "0 1px 4px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
            Legend
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            gap={2}
            sx={{ marginBottom: 1 }}
          >
            <ReportProblemIcon color="warning" />
            <Typography variant="body2">
              <strong>Misunderstood:</strong> Participant reported not
              understanding the concept in feedback.
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            gap={2}
            sx={{ marginBottom: 1 }}
          >
            <CheckCircleIcon color="success" />
            <Typography variant="body2">
              <strong>Understood:</strong> Participant did not report any
              misunderstanding in feedback.
            </Typography>
          </Box>
        </Paper>
      );
    }
  };

  const renderKeyConceptsTable = () => {
    const keyConcepts = getAllUniqueKeyConcepts(selectedSession);
    const participants = calculateParticipantPerformance();

    if (keyConcepts.length === 0) return null;

    return (
      <>
        <TableContainer
          component={Paper}
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: themeMode === "light" ? "#ffffff" : "#2c2c2c",
            borderRadius: 2,
            boxShadow:
              themeMode === "light"
                ? "0 1px 4px rgba(0, 0, 0, 0.1)"
                : "0 1px 4px rgba(0, 0, 0, 0.2)",
          }}
        >
          {/* Fixed Header Section */}
          <Box sx={{ p: 2 }}>
            {/* Include Tabs here */}
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="View Tabs"
            >
              <Tab label="Combined View" />
              <Tab label="Answer Correctness" />
              <Tab label="Feedback Misunderstandings" />
            </Tabs>
          </Box>

          {/* Scrollable Table Section */}
          <TableContainer
            sx={{
              flexGrow: 1,
              overflowX: "auto",
              overflowY: "hidden",
              "&::-webkit-scrollbar": {
                width: "8px",
                height: "8px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: themeMode === "light" ? "#f1f1f1" : "#1e1e1e",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: themeMode === "light" ? "#888" : "#555",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: themeMode === "light" ? "#555" : "#777",
              },
              borderTop: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      backgroundColor:
                        themeMode === "light" ? "#ffffff" : "#2c2c2c",
                      position: "sticky",
                      left: 0,
                      zIndex: 3,
                    }}
                  >
                    Participant
                  </TableCell>
                  {keyConcepts.map((concept, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        backgroundColor:
                          themeMode === "light" ? "#ffffff" : "#2c2c2c",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          gap: 1,
                        }}
                      >
                        <Typography sx={{ fontWeight: "bold" }}>
                          {concept.name}
                        </Typography>
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <Chip
                            label={concept.type}
                            size="small"
                            color={
                              concept.type === "HOTS" ? "error" : "primary"
                            }
                            variant={
                              concept.type === "HOTS" ? "filled" : "outlined"
                            }
                          />
                          <Chip
                            label={concept.skill_tag}
                            size="small"
                            color={
                              concept.type === "HOTS" ? "error" : "primary"
                            }
                            variant={
                              concept.type === "HOTS" ? "filled" : "outlined"
                            }
                          />
                        </Box>
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {participants.map((participant) => {
                  const keyConceptsNotUnderstood =
                    selectedSession.keyconceptNotUnderstood?.find(
                      (item) =>
                        item.participantId === participant.participantId,
                    );
                  const misunderstoodConcepts = keyConceptsNotUnderstood
                    ? JSON.parse(
                        keyConceptsNotUnderstood.key_concept_not_understood ||
                          "[]",
                      )
                    : [];

                  const comments = keyConceptsNotUnderstood?.comments || "";
                  const rating = keyConceptsNotUnderstood?.rating || null;

                  return (
                    <TableRow
                      key={participant.participantId}
                      sx={{
                        cursor: "pointer",
                        backgroundColor:
                          themeMode === "light" ? "#ffffff" : "#2c2c2c",
                        "&:hover": {
                          backgroundColor:
                            themeMode === "light" ? "#f5f5f5" : "#363636",
                        },
                      }}
                      onClick={() => {
                        setSelectedParticipant({
                          ...participant,
                          misunderstoodConcepts,
                          comments,
                          rating,
                        });
                        setDrawerOpen(true);
                      }}
                    >
                      <TableCell
                        sx={{
                          position: "sticky",
                          left: 0,
                          backgroundColor:
                            themeMode === "light" ? "#ffffff" : "#2c2c2c",
                          zIndex: 2,
                        }}
                      >
                        <Box display="flex" alignItems="center" gap={1}>
                          <Avatar
                            src={participant.participantAvatar}
                            alt={participant.participantName}
                            sx={{ width: 30, height: 30 }}
                          />
                          <Typography>{participant.participantName}</Typography>
                        </Box>
                      </TableCell>
                      {keyConcepts.map((concept, index) => {
                        const misunderstoodInFeedback =
                          misunderstoodConcepts.includes(concept.name);

                        const relatedResponses = participant.responses.filter(
                          (response) => response.keyConcept === concept.name,
                        );

                        const allCorrect = relatedResponses.every(
                          (response) => response.isCorrect,
                        );

                        let IconComponent = null;
                        let iconColor = "";

                        if (tabIndex === 0) {
                          // Combined View
                          if (allCorrect && misunderstoodInFeedback) {
                            IconComponent = ReportProblemIcon; // Contradiction
                            iconColor = "warning";
                          } else if (!allCorrect && misunderstoodInFeedback) {
                            IconComponent = CancelIcon;
                            iconColor = "error";
                          } else if (!allCorrect && !misunderstoodInFeedback) {
                            IconComponent = CancelIcon;
                            iconColor = "info"; // Overconfidence
                          } else if (allCorrect && !misunderstoodInFeedback) {
                            IconComponent = CheckCircleIcon;
                            iconColor = "success";
                          }
                        } else if (tabIndex === 1) {
                          // Answer Correctness
                          if (allCorrect) {
                            IconComponent = CheckCircleIcon;
                            iconColor = "success";
                          } else {
                            IconComponent = CancelIcon;
                            iconColor = "error";
                          }
                        } else if (tabIndex === 2) {
                          // Feedback Misunderstandings
                          if (misunderstoodInFeedback) {
                            IconComponent = ReportProblemIcon;
                            iconColor = "warning";
                          } else {
                            IconComponent = CheckCircleIcon;
                            iconColor = "success";
                          }
                        }

                        return (
                          <TableCell key={index} align="center">
                            {IconComponent && (
                              <IconComponent color={iconColor} />
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </TableContainer>
        {renderLegend(tabIndex)}
      </>
    );
  };

  const renderParticipantDrawer = () => {
    if (!selectedParticipant) return null;

    const {
      participantAvatar,
      participantName,
      participantSummary,
      misunderstoodConcepts,
      comments,
      rating,
      responses,
    } = selectedParticipant;

    return (
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          sx: { width: { xs: "100%", sm: "400px" } },
        }}
      >
        <Box sx={{ padding: 3 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 2,
            }}
          >
            <Box display="flex" alignItems="center" gap={2}>
              <Avatar
                src={participantAvatar}
                alt={participantName}
                sx={{ width: 60, height: 60 }}
              />
              <Typography variant="h6">{participantName}</Typography>
            </Box>
            <IconButton onClick={() => setDrawerOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Typography variant="body1" gutterBottom>
            <strong>Rating:</strong> {rating !== null ? rating : "N/A"}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Comments:</strong> {comments || "N/A"}
          </Typography>

          <Typography variant="body1" gutterBottom>
            <strong>Performance Summary:</strong>{" "}
            {participantSummary.individualPerformance}
          </Typography>

          {participantSummary.personalImprovementAreas.length > 0 && (
            <Typography variant="body1" gutterBottom>
              <strong>Areas for Improvement:</strong>
              <ul>
                {participantSummary.personalImprovementAreas.map(
                  (area, index) => (
                    <li key={index}>{area}</li>
                  ),
                )}
              </ul>
            </Typography>
          )}

          <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
            Session Details
          </Typography>

          {responses.map((response, idx) => (
            <Box
              key={idx}
              sx={{
                marginBottom: 2,
                padding: 2,
                borderRadius: 2,
                border: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Typography variant="body2" sx={{ marginBottom: 1 }}>
                <strong>Question:</strong> {response.question}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: response.isCorrect
                    ? theme.palette.success.main
                    : theme.palette.error.main,
                  marginBottom: 1,
                }}
              >
                <strong>Your Response:</strong> {response.response}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: theme.palette.text.primary }}
              >
                <strong>Correct Answer:</strong> {response.correctAnswer}
              </Typography>
            </Box>
          ))}
        </Box>
      </Drawer>
    );
  };

  return (
    <Box sx={{ padding: 3, maxWidth: "90%", margin: "0 auto" }}>
      {renderAggregatedSummary()}
      {renderUserSummary()}
      {renderQuestions()}
      {renderKeyConceptsTable()}
      {renderParticipantDrawer()}
    </Box>
  );
};

export default SessionDetails;

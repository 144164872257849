// components/IdleScreen.js

import React from "react";
import { Box, Typography, Button } from "@mui/material";
import Lottie from "react-lottie";
import loadinganimation from "./animations/disconnect.json";

const IdleScreen = ({ onReconnect }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadinganimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
        backgroundColor: "#121212",
        color: "#fff",
      }}
    >
      <Lottie options={defaultOptions} height={200} width={200} />
      <Typography variant="h4" gutterBottom>
        You have been disconnected due to inactivity.
      </Typography>
      <Button variant="contained" color="primary" onClick={onReconnect}>
        Reconnect
      </Button>
    </Box>
  );
};

export default IdleScreen;

import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  CssBaseline,
  ThemeProvider,
  createTheme,
  Typography,
} from "@mui/material";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import io from "socket.io-client";
import LoadingScreen from "./components/LoadingScreen";
import IdleScreen from "./components/IdleScreen";
import AppBar from "./components/AppBar";
import Drawer from "./components/Drawer";
import HostView from "./components/HostView";
import ParticipantView from "./components/ParticipantView";
import FinalResults from "./components/FinalResults";
//import LoadingQuestions from "./components/LoadingQuestions";
// import ReviewQuestions from "./components/ReviewQuestions";
import Login from "./components/Login";
import Create from "./components/Create";
import LandingPage from "./components/LandingPage";
import SessionDetails from "./components/SessionDetails";
import Join from "./components/Join";
import Dashboard from "./components/Dashboard";
import QuizzesList from "./components/QuizzesList";
import ParticipantList from "./components/ParticipantList";
import SessionsList from "./components/SessionsList";
import useQuizSessionManager from "./components/QuizSessionManager";
import QuizFeedback from "./components/Feedback";
import JoinRoute from "./components/JoinRoute";

import mixpanel from "mixpanel-browser";

// // Replace with your server URL
// const socket = io(
//   "https://c8e788ef-e1df-4ae7-b024-181fa88bf8d2-00-kw6mo9fimmcq.sisko.replit.dev/",
// );

// Replace with your server URL
const socket = io("https://server-interactive-learning-one.replit.app/");

mixpanel.init("d1e02ce712784d019abe7f2be3d2daf4", {
  debug: true,
  persistence: "localStorage",
  cross_subdomain_cookie: true,
}); // Replace with your actual project token

const currentDistinctId = mixpanel.get_distinct_id();
console.log("Current distinct_id:", currentDistinctId);

const App = () => {
  // Common state
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [participantId, setParticipantId] = useState(null);
  const [avatar, setAvatar] = useState("");
  const [mode, setMode] = useState("join");
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem("themeMode") || "light",
  );
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const [isIdle, setIsIdle] = useState(false);
  const [userTriggeredActivity, setUserTriggeredActivity] = useState(false);
  const [activeQuizSession, setActiveQuizSession] = useState(null);
  const [isHost, setIsHost] = useState(false);
  const [isLobbyView, setIsLobbyView] = useState(false); // Added state for isLobbyView

  // State for Create Component
  const [isProcessing, setIsProcessing] = useState(false);
  const [prompts, setPrompts] = useState([]);

  // State for ReviewQuestions Component
  const [editableQuestions, setEditableQuestions] = useState([]);
  const [quizId, setQuizId] = useState("");
  const [loadedQuizId, setLoadedQuizId] = useState("");
  const [additionalPrompt, setAdditionalPrompt] = useState("");
  const [isAddingQuestion, setIsAddingQuestion] = useState(false);

  // State for Host/Participant Views
  const [quizCode, setQuizCode] = useState("");
  const [title, setTitle] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [totalParticipants, setTotalParticipants] = useState(0);
  const [responses, setResponses] = useState(0);
  const [results, setResults] = useState({});
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [explanation, setExplanation] = useState("");
  const [answer, setAnswer] = useState("");
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [showAnswers, setShowAnswers] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [scores, setScores] = useState({});
  const [scoreGrid, setScoreGrid] = useState({});

  // State for FinalResults Component
  const [quizResults, setQuizResults] = useState(null);

  // State for Quiz List/Drawer
  const [quizzesList, setQuizzesList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredQuizzes, setFilteredQuizzes] = useState([]);

  const [selectedSession, setSelectedSession] = useState(null);
  const [loadingSession, setLoadingSession] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState(null);

  const [sessionId, setSessionId] = useState(null);
  const [keyConcepts, setKeyConcepts] = useState(null);

  // For host view only
  const [classroom, setClassroom] = useState(null);
  const [subject, setSubject] = useState(null);

  const {
    initiateQuizJoin,
    submitAnswerToServer,
    reloadQuizSession,
    handleNextQuestion,
  } = useQuizSessionManager({
    socket,
    userInfo,
    setMode,
    setActiveQuizSession,
    setQuizCode,
    setTitle,
    setCurrentQuestion,
    setCurrentQuestionIndex,
    setTotalQuestions,
    setParticipants,
    setIsHost,
    quizCode,
    title,
    isHost,
    setTotalParticipants,
    setResponses,
    setResults,
    setCorrectAnswer,
    setExplanation,
    setAnswer,
    setHasSubmitted,
    setShowAnswers,
    setParticipantId,
    setScores,
    setAvatar,
    setEditableQuestions,
    setQuizId,
    setLoadedQuizId,
    setScoreGrid,
    participantId,
    answer,
    hasSubmitted,
    activeQuizSession,
    setQuizResults,
    loadedQuizId,
    quizId,
    setSessionId,
    setKeyConcepts,
    setIsLobbyView,
    setClassroom,
    setSubject,
  });

  useEffect(() => {
    socket.on("disconnect", () => {
      setIsIdle(true);
    });
    return () => {
      socket.off("disconnect");
    };
  }, []);

  // THEME
  const theme = createTheme({
    palette: {
      mode: themeMode,
      primary: {
        main: "#5e52f7",
      },
      secondary: {
        main: "#ffeb3b", // Yellow
      },
      background: {
        default: themeMode === "light" ? "#f4f2ee" : "#424242",
        paper: themeMode === "light" ? "#ffffff" : "#424242",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            ...(themeMode === "dark" && {
              color: "#ffffff", // White text
              backgroundColor: "#5e52f7", // Purple button
              "&:hover": {
                backgroundColor: "#3f3bbf", // Custom hover color in dark mode
              },
            }),
          },
        },
      },
    },
  });

  const toggleThemeMode = () => {
    const newMode = themeMode === "light" ? "dark" : "light";
    setThemeMode(newMode);
    localStorage.setItem("themeMode", newMode);
  };
  // LOGIN AND RECONNECTION
  const idleTimeout = 5400000; // 90 minute idle time
  const idleTimer = useRef(null);

  const resetIdleTimer = useCallback(() => {
    if (isIdle && !userTriggeredActivity) return;

    setIsIdle(false);
    clearTimeout(idleTimer.current);
    idleTimer.current = setTimeout(() => {
      setIsIdle(true);
      setUserTriggeredActivity(false);
      socket.disconnect();
    }, idleTimeout);
  }, [idleTimeout, isIdle, userTriggeredActivity]);

  useEffect(() => {
    window.addEventListener("mousemove", resetIdleTimer);
    window.addEventListener("keydown", resetIdleTimer);
    window.addEventListener("scroll", resetIdleTimer);
    window.addEventListener("touchstart", resetIdleTimer);

    resetIdleTimer();

    return () => {
      window.removeEventListener("mousemove", resetIdleTimer);
      window.removeEventListener("keydown", resetIdleTimer);
      window.removeEventListener("scroll", resetIdleTimer);
      window.removeEventListener("touchstart", resetIdleTimer);
      clearTimeout(idleTimer.current);
    };
  }, [resetIdleTimer]);

  const handleReconnect = () => {
    setIsIdle(false);
    setUserTriggeredActivity(true);
    resetIdleTimer();
    socket.connect();
    validateToken();
  };

  const validateToken = useCallback(() => {
    const token = localStorage.getItem("authToken");
    const intendedRole = localStorage.getItem("intendedRole");
    const distinctId = mixpanel.get_distinct_id();

    if (token) {
      socket.emit("validateToken", { token, intendedRole, distinctId });
    } else {
      setIsLoggedIn(false);
      setIsCheckingAuth(false);
    }
  }, []);

  const onLoginSuccess = (user) => {
    setUserInfo(user);
    validateToken();
    setIsCheckingAuth(true);
  };

  useEffect(() => {
    socket.on(
      "userTokenValidationResult",
      ({ isValid, user, participantId, avatar }) => {
        if (isValid) {
          setUserInfo(user);
          setParticipantId(participantId);
          setAvatar(avatar);

          console.log("participantId", participantId);

          // Track the event with Mixpanel

          mixpanel.alias(participantId, currentDistinctId);

          mixpanel.track("User Logged In");

          //console.log("user role is ", user.role);

          mixpanel.identify(participantId);

          // Set Mixpanel people properties, including role
          mixpanel.people.set({
            role: user.role, // Custom property for user role
          });

          // Clear intendedRole from localStorage
          localStorage.removeItem("intendedRole");

          if (participantId) {
            if (user.role === "Teacher") {
              setMode("quizzesList");
            } else {
              setMode("join"); // Redirect participants to the join screen
            }
            setIsLoggedIn(true);
          }
        } else {
          localStorage.removeItem("authToken");
          setIsLoggedIn(false);
        }
        setIsCheckingAuth(false);
      },
    );

    return () => {
      socket.off("userTokenValidationResult");
    };
  }, []);

  //
  // Listen for lobbyview updates
  useEffect(() => {
    socket.on("lobbyviewUpdated", (data) => {
      setIsLobbyView(data.lobbyview);
      console.log("Lobbyview updated:", data.lobbyview);
    });

    return () => {
      socket.off("lobbyviewUpdated");
    };
  }, []);

  // Listen for lobbyview status
  useEffect(() => {
    socket.on("lobbyviewStatus", (data) => {
      setIsLobbyView(data.lobbyview);
      console.log("Initial lobbyview status:", data.lobbyview);
    });

    return () => {
      socket.off("lobbyviewStatus");
    };
  }, []);

  // Emit checkLobbyview when quizCode changes
  useEffect(() => {
    if (quizCode) {
      socket.emit("checkLobbyview", { quizCode });
    }
  }, [quizCode]);

  // // REVIEW
  // const onServerQuestionsGenerated = useCallback(
  //   ({ questions, title, quizId, content }) => {
  //     setTitle(title);
  //     setQuizId(quizId);
  //     setEditableQuestions(
  //       questions.map((q, index) => ({
  //         ...q,
  //         id: index,
  //         contentId: content[index]?.id,
  //         type: content[index]?.type,
  //       })),
  //     );
  //     setAdditionalPrompt(searchQuery);
  //     setMode("reviewQuestions");
  //   },
  //   [searchQuery],
  // );

  // Drawer
  const toggleDrawer = (isOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(isOpen);
    if (isOpen) {
      //socket.emit("getQuizzes");
    }
  };

  // Other
  const onServerError = useCallback((message) => {
    if (message) {
      alert(message);
    }
    setMode("join");
    setActiveQuizSession(null); // Reset the active quiz session
  }, []);

  // Initial Effects
  useEffect(() => {
    validateToken();
  }, [validateToken]);

  // // Effects for Create Component
  // useEffect(() => {
  //   socket.on("questionsGeneratedSuccess", onServerQuestionsGenerated);

  //   return () => {
  //     socket.off("questionsGeneratedSuccess", onServerQuestionsGenerated);
  //   };
  // }, [onServerQuestionsGenerated]);

  // Other
  useEffect(() => {
    socket.on("socketError", onServerError);

    return () => {
      socket.off("socketError", onServerError);
    };
  }, [onServerError]);

  useEffect(() => {
    socket.on("logout", (message) => {
      alert(message || "You have been logged out.");
      document.body.innerHTML = "<h1>You have been logged out.....</h1>";
    });

    return () => {
      socket.off("logout");
    };
  }, []);

  useEffect(() => {
    const handleUnload = () => {
      socket.emit("disconnect");
    };

    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("unload", handleUnload);
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isHost) {
        const message =
          "Closing this window will end the quiz for all participants.";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isHost]);

  // if (participantId) {
  //   socket.emit("getSessionsByHost", { participantId });
  // }

  if (isCheckingAuth) {
    return <LoadingScreen />;
  }

  if (!isLoggedIn) {
    return (
      <Router>
        <Routes>
          <Route
            path="/login"
            element={<Login onLoginSuccess={onLoginSuccess} />}
          />
          <Route
            path="/join/:quizCode"
            element={
              <JoinRoute
                participantId={participantId}
                initiateQuizJoin={initiateQuizJoin}
              />
            }
          />

          <Route
            path="*"
            element={<LandingPage mixpanel={mixpanel} to="/" />}
          />
        </Routes>
      </Router>
    );
  }

  if (isIdle) {
    return <IdleScreen onReconnect={handleReconnect} />;
  }

  const renderContent = () => {
    switch (mode) {
      case "dashboard":
        return <Dashboard socket={socket} participantId={participantId} />;

      case "choose":
        return userInfo?.role === "Teacher" ? (
          <Create
            socket={socket}
            isProcessing={isProcessing}
            setIsProcessing={setIsProcessing}
            prompts={prompts}
            setPrompts={setPrompts}
            setMode={setMode}
            participantId={participantId}
            additionalPrompt={additionalPrompt}
            setAdditionalPrompt={setAdditionalPrompt}
            isAddingQuestion={isAddingQuestion}
            setIsAddingQuestion={setIsAddingQuestion}
            editableQuestions={editableQuestions}
            setEditableQuestions={setEditableQuestions}
            quizId={quizId}
            setQuizId={setQuizId}
            title={title}
            setTitle={setTitle}
            themeMode={themeMode}
            mixpanel={mixpanel}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              textAlign: "center",
            }}
          >
            <Typography variant="h6" color="error">
              You do not have permission to create quizzes.
            </Typography>
          </Box>
        );
      case "join":
        return (
          <Join
            initiateQuizJoin={initiateQuizJoin}
            quizCode={quizCode}
            setQuizCode={setQuizCode}
          />
        );
      // case "loadingQuestions":
      //   return <LoadingQuestions />;
      // case "reviewQuestions":
      //   return (
      //     <ReviewQuestions
      //       socket={socket}
      //       participantId={participantId}
      //       searchQuery={searchQuery}
      //       setIsAddingQuestion={setIsAddingQuestion}
      //       editableQuestions={editableQuestions}
      //       isAddingQuestion={isAddingQuestion}
      //       additionalPrompt={additionalPrompt}
      //       setAdditionalPrompt={setAdditionalPrompt}
      //       quizId={quizId}
      //       title={title}
      //       loadedQuizId={loadedQuizId}
      //       setEditableQuestions={setEditableQuestions}
      //     />
      //   );
      case "hostActive":
        return (
          <HostView
            socket={socket}
            title={title}
            quizCode={quizCode}
            totalParticipants={totalParticipants}
            currentQuestion={currentQuestion}
            currentQuestionIndex={currentQuestionIndex}
            totalQuestions={totalQuestions}
            results={results}
            answer={answer}
            hasSubmitted={hasSubmitted}
            correctAnswer={correctAnswer}
            explanation={explanation}
            showAnswers={showAnswers}
            setShowAnswers={setShowAnswers}
            responses={responses}
            participants={participants}
            scores={scores}
            isHost={isHost}
            handleNextQuestion={handleNextQuestion}
            handleSubmitAnswer={submitAnswerToServer}
            setAnswer={setAnswer}
            participantId={participantId}
            scoreGrid={scoreGrid}
            activeQuizSession={activeQuizSession}
            setActiveQuizSession={setActiveQuizSession}
            reloadQuizSession={reloadQuizSession}
            mode={mode}
            isLobbyView={isLobbyView} // Passed isLobbyView to HostView
            themeMode={themeMode}
            classroom={classroom}
            subject={subject}
            setClassroom={setClassroom}
            setSubject={setSubject}
            mixpanel={mixpanel}
          />
        );
      case "participantActive":
        return (
          <ParticipantView
            socket={socket}
            currentQuestion={currentQuestion}
            currentQuestionIndex={currentQuestionIndex}
            totalQuestions={totalQuestions}
            totalParticipants={totalParticipants}
            title={title}
            answer={answer}
            setAnswer={setAnswer}
            handleSubmitAnswer={submitAnswerToServer}
            hasSubmitted={hasSubmitted}
            showAnswers={showAnswers}
            correctAnswer={correctAnswer}
            explanation={explanation}
            avatar={avatar}
            quizCode={quizCode}
            isLobbyView={isLobbyView} // Passed isLobbyView to ParticipantView
          />
        );
      case "results":
        return (
          <FinalResults
            finalResults={quizResults?.finalResults}
            participantAnswers={quizResults?.participantAnswers}
            scores={quizResults?.scores}
            totalParticipants={totalParticipants}
            participantId={participantId}
            isHost={isHost}
            title={quizResults.title}
            quizId={quizResults.quizId}
            code={quizResults.code}
            setMode={setMode}
            themeMode={themeMode}
          />
        );

      case "sessionDetails":
        return (
          <SessionDetails
            loadingSession={loadingSession}
            selectedSession={selectedSession}
            themeMode={themeMode}
            pId={participantId}
            mixpanel={mixpanel}
          />
        );

      case "quizzesList":
        return (
          <QuizzesList
            socket={socket}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            filteredQuizzes={filteredQuizzes}
            quizzesList={quizzesList}
            loadedQuizId={loadedQuizId}
            quizId={quizId}
            setFilteredQuizzes={setFilteredQuizzes}
            setQuizId={setQuizId}
            setTitle={setTitle}
            setEditableQuestions={setEditableQuestions}
            setMode={setMode}
            setLoadedQuizId={setLoadedQuizId}
            setQuizzesList={setQuizzesList}
            participantId={participantId}
            setSelectedSession={setSelectedSession}
            themeMode={themeMode}
            mixpanel={mixpanel}
          />
        );

      case "sessionsList":
        return (
          <SessionsList
            socket={socket}
            participantId={participantId}
            setMode={setMode}
            setSelectedSession={setSelectedSession}
            themeMode={themeMode}
            mixpanel={mixpanel}
          />
        );

      case "participantList":
        return (
          <ParticipantList
            socket={socket}
            participantId={participantId}
            setMode={setMode}
            setSelectedParticipant={setSelectedParticipant}
            themeMode={themeMode}
            mixpanel={mixpanel}
          />
        );

      case "feedback":
        return (
          <QuizFeedback
            socket={socket}
            participantId={participantId}
            title={title}
            setMode={setMode}
            sessionId={sessionId}
            keyConcepts={keyConcepts}
          />
        );

      default:
        return null;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Box sx={{ display: "flex" }}>
          {/* AppBar */}
          <AppBar
            socket={socket}
            activeQuizSession={activeQuizSession}
            userInfo={userInfo}
            toggleDrawer={toggleDrawer}
            setMode={setMode}
            reloadQuizSession={reloadQuizSession}
            avatar={avatar}
            isLoggedIn={isLoggedIn}
            setActiveQuizSession={setActiveQuizSession}
            quizCode={quizCode}
            mode={mode}
            anchorEl={anchorEl}
            themeMode={themeMode}
            toggleThemeMode={toggleThemeMode}
            setUserInfo={setUserInfo}
            setAnchorEl={setAnchorEl}
            setParticipantId={setParticipantId}
            setIsLoggedIn={setIsLoggedIn}
            participantId={participantId}
            setAvatar={setAvatar}
            mixpanel={mixpanel}
          />

          <Drawer
            userInfo={userInfo}
            socket={socket}
            drawerOpen={drawerOpen}
            toggleDrawer={toggleDrawer}
            searchQuery={searchQuery}
            filteredQuizzes={filteredQuizzes}
            loadedQuizId={loadedQuizId}
            quizId={quizId}
            setLoadedQuizId={setLoadedQuizId}
            setSearchQuery={setSearchQuery}
            setFilteredQuizzes={setFilteredQuizzes}
            quizzesList={quizzesList}
            setQuizId={setQuizId}
            setTitle={setTitle}
            setMode={setMode}
            mode={mode}
            setEditableQuestions={setEditableQuestions}
            setQuizzesList={setQuizzesList}
            participantId={participantId}
            setSelectedSession={setSelectedSession}
          />

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              mt: 8,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Routes>
              <Route
                path="/join/:quizCode"
                element={
                  <JoinRoute
                    participantId={participantId}
                    initiateQuizJoin={initiateQuizJoin}
                  />
                }
              />
              <Route path="*" element={renderContent()} />
            </Routes>
          </Box>
        </Box>
      </Router>
    </ThemeProvider>
  );
};
export default App;

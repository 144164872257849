import React, { useCallback, useState } from "react";
import {
  Box,
  IconButton,
  Menu,
  Typography,
  Divider,
  Button,
  useTheme,
  alpha,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Avatar from "@mui/material/Avatar";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import LogoutIcon from "@mui/icons-material/Logout";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const UserMenu = ({
  socket,
  avatar,
  anchorEl,
  userInfo,
  themeMode,
  toggleThemeMode,
  setUserInfo,
  setAnchorEl,
  setParticipantId,
  setIsLoggedIn,
  participantId,
  setAvatar,
  mixpanel,
}) => {
  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleLogout = useCallback(() => {
    if (mixpanel) {
      mixpanel.track("User Sign Out", {
        user: userInfo?.email,
      });
    }
    setIsLoggedIn(false);
    setUserInfo(null);
    setParticipantId(null);
    localStorage.removeItem("authToken");
    socket.emit("logout");
    window.location.href = "/login";
  }, [
    mixpanel,
    userInfo,
    setIsLoggedIn,
    setUserInfo,
    setParticipantId,
    socket,
  ]);

  const handleMenuClick = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleChangeAvatar = useCallback(() => {
    const newAvatarUrl = `https://api.dicebear.com/9.x/adventurer/svg?seed=${Math.random()
      .toString(36)
      .substr(2, 9)}`;
    socket.emit("changeAvatar", { participantId, newAvatarUrl });
    setAvatar(newAvatarUrl);
    if (mixpanel) {
      mixpanel.track("Avatar Change Clicked", {
        user: userInfo?.email,
        newAvatarUrl,
      });
    }
  }, [socket, participantId, setAvatar, mixpanel, userInfo]);

  const handleThemeToggle = useCallback(() => {
    toggleThemeMode();
    if (mixpanel) {
      mixpanel.track("Theme Change", {
        user: userInfo?.email,
        newTheme: themeMode === "light" ? "dark" : "light",
      });
    }
  }, [toggleThemeMode, mixpanel, userInfo, themeMode]);

  // Updated function to open the dialog
  const handleContactSupport = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const menuStyles = {
    "& .MuiPaper-root": {
      width: { xs: "280px", sm: "320px" },
      maxWidth: "320px",
      backgroundColor: themeMode === "light" ? "#ffffff" : "#2c2c2c",
      borderRadius: "8px",
      marginTop: "8px",
      boxShadow:
        themeMode === "light"
          ? "0 4px 20px rgba(0, 0, 0, 0.1)"
          : "0 4px 20px rgba(0, 0, 0, 0.3)",
      border: `1px solid ${
        themeMode === "light"
          ? alpha(theme.palette.divider, 0.1)
          : alpha(theme.palette.divider, 0.2)
      }`,
    },
  };

  const buttonStyles = {
    borderRadius: "6px",
    textTransform: "none",
    padding: "6px 12px",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      transform: "translateY(-1px)",
      boxShadow:
        themeMode === "light"
          ? "0 4px 12px rgba(0, 0, 0, 0.1)"
          : "0 4px 12px rgba(0, 0, 0, 0.3)",
    },
  };

  return (
    <>
      <IconButton
        color="inherit"
        onClick={handleMenuClick}
        sx={{
          padding: "4px",
          "&:hover": {
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
          },
        }}
      >
        {avatar ? (
          <Avatar
            src={avatar}
            alt="User Avatar"
            sx={{
              width: 32,
              height: 32,
              border: `1px solid ${theme.palette.primary.main}`,
            }}
          />
        ) : (
          <AccountCircleIcon sx={{ width: 32, height: 32 }} />
        )}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={menuStyles}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Box sx={{ position: "relative", mb: 1.5 }}>
              {avatar && (
                <Avatar
                  src={avatar}
                  alt="User Avatar"
                  sx={{
                    width: 60,
                    height: 60,
                    border: `2px solid ${theme.palette.primary.main}`,
                    backgroundColor: themeMode === "light" ? "#f5f5f5" : "#333",
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
                    transition: "all 0.2s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.05)",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                />
              )}
              <IconButton
                color="primary"
                onClick={handleChangeAvatar}
                size="small"
                sx={{
                  position: "absolute",
                  bottom: -8,
                  right: -8,
                  backgroundColor: theme.palette.background.paper,
                  boxShadow: theme.shadows[1],
                  padding: "4px",
                  "&:hover": {
                    backgroundColor: alpha(theme.palette.primary.main, 0.1),
                  },
                }}
              >
                <ShuffleIcon fontSize="small" />
              </IconButton>
            </Box>
            <Typography variant="h6" sx={{ mb: 0.5, fontWeight: 600 }}>
              {userInfo.name}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                mb: 0.5,
                color: theme.palette.primary.main,
                fontWeight: 500,
              }}
            >
              {userInfo.role}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                color: alpha(theme.palette.text.primary, 0.7),
              }}
            >
              {userInfo.email}
            </Typography>
          </Box>
          <Divider
            sx={{
              my: 1.5,
              borderColor: alpha(theme.palette.divider, 0.1),
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="body2">Theme Mode</Typography>
            <IconButton
              color="primary"
              onClick={handleThemeToggle}
              size="small"
              sx={{
                backgroundColor: alpha(theme.palette.primary.main, 0.1),
                "&:hover": {
                  backgroundColor: alpha(theme.palette.primary.main, 0.2),
                },
              }}
            >
              {themeMode === "light" ? (
                <Brightness4Icon fontSize="small" />
              ) : (
                <Brightness7Icon fontSize="small" />
              )}
            </IconButton>
          </Box>
          {/* Logout Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleLogout();
              handleMenuClose();
            }}
            fullWidth
            size="small"
            startIcon={<LogoutIcon />}
            sx={{
              ...buttonStyles,
              py: 1,
            }}
          >
            Logout
          </Button>
          {/* Contact Support Button for Teachers */}
          {userInfo.role === "Teacher" && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleContactSupport}
              fullWidth
              size="small"
              startIcon={<HelpOutlineIcon />}
              sx={{
                ...buttonStyles,
                py: 1,
                mt: 1, // Add margin-top for spacing
              }}
            >
              Contact Support
            </Button>
          )}
        </Box>
      </Menu>

      {/* Contact Support Dialog */}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="contact-support-dialog"
      >
        <DialogTitle id="contact-support-dialog">Contact Support</DialogTitle>
        <DialogContent>
          <Typography sx={{ mb: 2 }}>
            I am a solo entrepreneur from India and love to build things. I am
            developing this application rapidly and would love to hear from you!
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Typography sx={{ mb: 1 }}>Please contact me at:</Typography>
          <Typography sx={{ fontWeight: "bold", mb: 2 }}>
            jjiteshh@gmail.com
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Typography sx={{ mb: 1 }}>
            You can also connect with me on LinkedIn:
          </Typography>
          <Button
            variant="contained"
            color="primary"
            href="https://www.linkedin.com/in/jiteshganage/"
            target="_blank"
            rel="noopener noreferrer"
            startIcon={<LinkedInIcon />}
            sx={{ textTransform: "none" }}
          >
            LinkedIn Profile
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserMenu;

import React, { useCallback, useEffect, useState, useMemo } from "react";
import {
  TextField,
  Card,
  CardContent,
  Typography,
  IconButton,
  Divider,
  Box,
  Grid,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { debounce } from "lodash";

const QuizzesList = ({
  socket,
  searchQuery,
  setSearchQuery,
  filteredQuizzes,
  quizzesList,
  loadedQuizId,
  quizId,
  setFilteredQuizzes,
  setQuizId,
  setTitle,
  setEditableQuestions,
  setMode,
  setLoadedQuizId,
  setQuizzesList,
  themeMode,
  mixpanel,
}) => {
  const [loading, setLoading] = useState(true); // Track loading state

  // Track when the component is viewed
  useEffect(() => {
    mixpanel.track("Quizzes List Viewed");
  }, [mixpanel]);

  const handleCreateNewQuiz = useCallback(() => {
    mixpanel.track("New Quiz Initiated");
    setQuizId("");
    setTitle("");
    setEditableQuestions([]);
    setMode("choose");
  }, [mixpanel, setQuizId, setTitle, setEditableQuestions, setMode]);

  // Debounced Search Query Handler
  const debouncedSearchQueryChange = useMemo(
    () =>
      debounce((query) => {
        if (query.trim() === "") {
          setFilteredQuizzes(quizzesList);
        } else {
          const filtered = quizzesList.filter((quiz) =>
            quiz.title.toLowerCase().includes(query.toLowerCase()),
          );
          setFilteredQuizzes(filtered);
        }
      }, 300), // Wait 300ms before filtering
    [quizzesList, setFilteredQuizzes],
  );

  const handleSearchQueryChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    debouncedSearchQueryChange(query);
  };

  const formatToLocalTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const requestLoadQuiz = useCallback(
    (quizId) => {
      mixpanel.track("Quiz Loaded", { quizId });
      setLoading(true);
      socket.emit("loadQuiz", { quizId });
      setLoadedQuizId(quizId);
    },
    [mixpanel, socket, setLoadedQuizId],
  );

  const handleDeleteQuiz = useCallback(
    (quizId) => {
      if (window.confirm("Are you sure you want to delete this quiz?")) {
        mixpanel.track("Quiz Deleted", { quizId });
        socket.emit("deleteQuiz", { quizId });
      }
    },
    [mixpanel, socket],
  );

  useEffect(() => {
    const loadStartTime = Date.now();
    socket.emit("getQuizzes");

    socket.on("quizDeleteSuccess", ({ quizId }) => {
      setQuizzesList((prev) => prev.filter((quiz) => quiz.id !== quizId));
      setFilteredQuizzes((prev) => prev.filter((quiz) => quiz.id !== quizId));

      if (loadedQuizId === quizId) {
        setLoadedQuizId("");
        setQuizId("");
        setMode("choose");
      }
    });

    socket.on("quizLoadSuccess", (quiz) => {
      const loadedQuestions = quiz.content.map((item) => ({
        contentId: item.contentId,
        quizId: item.quizId,
        type: item.type,
        order: item.order,
        ...item.data,
      }));

      setEditableQuestions(loadedQuestions);
      setTitle(quiz.title);
      setQuizId(quiz.quizId);
      setMode("choose");
      setLoading(false);

      mixpanel.track("Quiz Load Success", {
        quizId: quiz.quizId,
        title: quiz.title,
        questionCount: loadedQuestions.length,
      });
    });

    socket.on("savedQuizzesList", (quizzes) => {
      setQuizzesList(quizzes);
      setFilteredQuizzes(quizzes);
      setLoading(false);

      const loadDuration = Date.now() - loadStartTime;
      mixpanel.track("Quizzes Loaded", { loadDuration });
    });

    setLoading(true);

    return () => {
      socket.off("quizDeleteSuccess");
      socket.off("quizLoadSuccess");
      socket.off("savedQuizzesList");
    };
  }, [
    socket,
    setQuizzesList,
    setFilteredQuizzes,
    loadedQuizId,
    setLoadedQuizId,
    setQuizId,
    setMode,
    setEditableQuestions,
    setTitle,
    mixpanel,
  ]);

  return (
    <Box
      sx={{
        width: "90%",
        marginLeft: "50px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <TextField
        label="Search Quizzes"
        value={searchQuery}
        onChange={handleSearchQueryChange}
        sx={{ width: "100%", mb: 2 }}
      />
      <Divider sx={{ my: 2 }} />

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card
            sx={{
              width: "100%",
              height: 200,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              backgroundColor: themeMode === "light" ? "#ffffff" : "#2c2c2c",
              borderRadius: "12px",
              boxShadow:
                themeMode === "light"
                  ? "0 2px 8px rgba(0, 0, 0, 0.08)"
                  : "0 2px 8px rgba(0, 0, 0, 0.15)",
              transition: "all 0.3s ease-in-out",
              border: "1px solid transparent",
              "&:hover": {
                borderColor: "#5e52f7",
                boxShadow: "0 4px 12px rgba(94, 82, 247, 0.2)",
                transform: "translateY(-3px)",
              },
            }}
            onClick={handleCreateNewQuiz}
          >
            <CardContent sx={{ textAlign: "center" }}>
              <AddIcon sx={{ fontSize: "2rem", color: "grey" }} />
              <Typography variant="h6" sx={{ fontWeight: "bold", mt: 1 }}>
                New Quiz
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {filteredQuizzes.length === 0 ? (
          <Grid item xs={12}>
            <Typography
              variant="h6"
              align="center"
              sx={{ color: "grey", fontWeight: "bold" }}
            >
              No quizzes found..
            </Typography>
          </Grid>
        ) : (
          filteredQuizzes
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            .map((quiz) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={quiz.id}>
                <Card
                  sx={{
                    width: "100%",
                    height: 200,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor:
                      themeMode === "light" ? "#ffffff" : "#2c2c2c",
                    borderRadius: "12px",
                    boxShadow:
                      themeMode === "light"
                        ? "0 2px 8px rgba(0, 0, 0, 0.08)"
                        : "0 2px 8px rgba(0, 0, 0, 0.15)",
                    transition: "all 0.3s ease-in-out",
                    border: "1px solid transparent",
                    "&:hover": {
                      borderColor: "#5e52f7",
                      boxShadow: "0 4px 12px rgba(94, 82, 247, 0.2)",
                      transform: "translateY(-3px)",
                    },
                  }}
                  onClick={() => requestLoadQuiz(quiz.id)}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%", // Ensure content fills the card height
                      textAlign: "center",
                    }}
                  >
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      {quiz.title}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "grey", mt: 1 }}>
                      {formatToLocalTime(quiz.created_at)}
                    </Typography>
                  </CardContent>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteQuiz(quiz.id);
                    }}
                    sx={{ position: "absolute", top: 15, right: 20 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Card>
              </Grid>
            ))
        )}
      </Grid>
    </Box>
  );
};

export default QuizzesList;

import { useCallback, useEffect } from "react";
const useQuizSessionManager = ({
  socket,
  userInfo,
  setMode,
  setActiveQuizSession,
  setQuizCode,
  setTitle,
  setCurrentQuestion,
  setCurrentQuestionIndex,
  setTotalQuestions,
  setParticipants,
  setIsHost,
  quizCode,
  title,
  isHost,
  setTotalParticipants,
  setResponses,
  setResults,
  setCorrectAnswer,
  setExplanation,
  setAnswer,
  setHasSubmitted,
  setShowAnswers,
  setParticipantId,
  setScores,
  setAvatar,
  setEditableQuestions,
  setQuizId,
  setLoadedQuizId,
  setScoreGrid,
  participantId,
  answer,
  hasSubmitted,
  activeQuizSession,
  setQuizResults,
  loadedQuizId,
  quizId,
  setSessionId,
  setKeyConcepts,
  setIsLobbyView,
  setClassroom,
  setSubject,
}) => {
  // Quiz Session Manager
  const onServerQuizSessionCreated = useCallback(
    ({ quizCode, title, questions, participantId, avatar, sessionId }) => {
      setQuizCode("");
      setTitle("");
      setCurrentQuestion(null);
      setCurrentQuestionIndex(0);
      setTotalQuestions(0);
      setTotalParticipants(0);
      setResponses(0);
      setResults({});
      setCorrectAnswer("");
      setExplanation("");
      setAnswer("");
      setHasSubmitted(false);
      setShowAnswers(false);
      setParticipants([]);
      setParticipantId(null);
      setScores({});
      setAvatar("");
      setEditableQuestions([]);
      setQuizId("");
      setLoadedQuizId("");
      setActiveQuizSession(null);
      setScoreGrid([]);
      setQuizCode(quizCode);
      setTitle(title);
      setTotalQuestions(questions.length);
      setCurrentQuestionIndex(0);
      setCurrentQuestion(questions[0]);
      setParticipantId(participantId);
      setAvatar(avatar);
      setParticipants([{ id: participantId, avatar }]);
      setTotalParticipants(1);
      setMode("hostActive");
      setIsHost(true);
      setActiveQuizSession({ quizCode, title, sessionId });
    },
    [
      setActiveQuizSession,
      setAnswer,
      setAvatar,
      setCorrectAnswer,
      setCurrentQuestion,
      setCurrentQuestionIndex,
      setEditableQuestions,
      setExplanation,
      setHasSubmitted,
      setIsHost,
      setLoadedQuizId,
      setMode,
      setParticipantId,
      setParticipants,
      setQuizCode,
      setQuizId,
      setResponses,
      setResults,
      setScoreGrid,
      setScores,
      setShowAnswers,
      setTitle,
      setTotalParticipants,
      setTotalQuestions,
    ],
  );

  // Quiz Session Manager
  const onServerQuizJoined = useCallback(
    ({
      quizCode,
      sessionId,
      title,
      question,
      options,
      keyConcepts,
      currentQuestionIndex,
      totalQuestions,
      participants,
      avatar,
    }) => {
      setSessionId(sessionId);
      setKeyConcepts(keyConcepts);
      setCurrentQuestion(null);
      setCurrentQuestionIndex(0);
      setTotalQuestions(0);
      setAnswer("");
      setHasSubmitted(false);
      setCorrectAnswer("");
      setExplanation("");
      setShowAnswers(false);
      setTotalParticipants(0);
      setResponses(0);
      setResults({});
      setScoreGrid({});

      setQuizCode(quizCode);
      setTitle(title);
      setCurrentQuestion({ question, options });
      setCurrentQuestionIndex(currentQuestionIndex);
      setTotalQuestions(totalQuestions);
      setParticipants(participants || []);
      setTotalParticipants(participants.length);
      setAvatar(avatar);

      setActiveQuizSession({ quizCode, title });
      setMode("participantActive");
    },
    [
      setActiveQuizSession,
      setAnswer,
      setAvatar,
      setCorrectAnswer,
      setCurrentQuestion,
      setCurrentQuestionIndex,
      setExplanation,
      setHasSubmitted,
      setMode,
      setParticipants,
      setQuizCode,
      setResponses,
      setResults,
      setScoreGrid,
      setShowAnswers,
      setTitle,
      setTotalParticipants,
      setTotalQuestions,
      setSessionId,
      setKeyConcepts,
    ],
  );

  // Quiz Session Manager
  const onServerQuestionStart = useCallback(
    ({ questionIndex, totalQuestions, question, options }) => {
      setCurrentQuestionIndex(questionIndex);
      setTotalQuestions(totalQuestions);
      setCurrentQuestion({ question, options });
      setAnswer("");
      setHasSubmitted(false);
      setResults({});
      setCorrectAnswer("");
      setExplanation("");
      setShowAnswers(false);
      setResponses(0);
    },
    [
      setAnswer,
      setCorrectAnswer,
      setCurrentQuestion,
      setCurrentQuestionIndex,
      setExplanation,
      setHasSubmitted,
      setResponses,
      setResults,
      setShowAnswers,
      setTotalQuestions,
    ],
  );

  // Quiz Session Manager
  const onServerUpdateResponse = useCallback(
    ({ responses }) => {
      setResponses(responses);
    },
    [setResponses],
  );

  // Quiz Session Manager
  const onServerShowAnswers = useCallback(
    ({ results, correctAnswer, explanation, scoreGrid, scores }) => {
      setResults(results);
      setCorrectAnswer(correctAnswer);
      setExplanation(explanation);
      setShowAnswers(true);
      setScoreGrid(scoreGrid);
      setScores(scores);
    },
    [
      setCorrectAnswer,
      setExplanation,
      setResults,
      setScoreGrid,
      setScores,
      setShowAnswers,
    ],
  );

  // Quiz Session Manager
  const handleNextQuestion = useCallback(() => {
    setShowAnswers(false);
    setResponses(0);
    socket.emit("nextQuestion", quizCode);
  }, [setShowAnswers, setResponses, socket, quizCode]);

  // Quiz Session Manager
  const onServerQuizEnd = useCallback(() => {
    setCurrentQuestion(null);
    if (isHost) {
      setMode("results");
    } else {
      setMode("feedback");
    }
    setActiveQuizSession(null);
  }, [setCurrentQuestion, setMode, setActiveQuizSession, isHost]);

  // Quiz Session Manager
  const onServerFinalResults = useCallback(
    ({ finalResults, participantAnswers, scores, title, quizId, code }) => {
      setQuizResults({
        finalResults,
        participantAnswers,
        scores,
        title,
        quizId,
        code,
      });
    },
    [setQuizResults],
  );

  // Quiz Session Manager
  const initiateQuizJoin = useCallback(
    (code = quizCode) => {
      if (!participantId) return;
      socket.emit("joinQuizSession", {
        quizCode: code,
        participantId,
      });
      setMode(isHost ? "hostActive" : "participantActive");
      setActiveQuizSession({ quizCode: code, title });
    },
    [
      title,
      quizCode,
      isHost,
      participantId,
      setActiveQuizSession,
      setMode,
      socket,
    ],
  );

  // Quiz Session Manager
  const submitAnswerToServer = useCallback(() => {
    if (answer && !hasSubmitted) {
      socket.emit("submitAnswer", { quizCode, answer, participantId, isHost });
      setHasSubmitted(true);
    }
  }, [
    answer,
    hasSubmitted,
    quizCode,
    participantId,
    isHost,
    setHasSubmitted,
    socket,
  ]);

  // Quiz Session Manager
  const reloadQuizSession = useCallback(() => {
    if (activeQuizSession) {
      initiateQuizJoin(activeQuizSession.quizCode);
    }
  }, [activeQuizSession, initiateQuizJoin]);

  // Quiz Session Manager
  const onServerUpdateParticipants = useCallback(
    ({ count, participants }) => {
      setTotalParticipants(count);
      setParticipants(participants || []);

      console.log("participants data", participants);
    },
    [setTotalParticipants, setParticipants],
  );

  // Quiz Session Manager
  const onServerUpdateScores = useCallback(
    (scores) => {
      setScores(scores);
    },
    [setScores],
  );

  const handleRejoinedQuizSession = (data) => {
    console.log("Rejoined quiz session data:", data);
    //console.log("participand id is :", participantId);

    const {
      quizCode,
      title,
      sessionId,
      currentQuestionIndex,
      totalQuestions,
      host,
      participantId,
      currentQuestion,
      participants = [],
      responses,
      participantAnswer,
      isLobbyView,
      keyConcepts,
      revealQuestionAnswer,
      results,
      scores,
      scoreGrid,
      participantAnswers,
      classroom,
      subject,
    } = data;

    setParticipantId(participantId);
    setIsLobbyView(isLobbyView);
    const isHost = participantId.toString() === host.toString();
    setIsHost(isHost);

    setQuizCode(quizCode);
    setTitle(title);
    setSessionId(sessionId);
    setCurrentQuestionIndex(currentQuestionIndex);
    setTotalQuestions(totalQuestions);
    setParticipants(participants);
    setTotalParticipants(participants.length);
    setResponses(responses);
    setKeyConcepts(keyConcepts);
    setActiveQuizSession({
      quizCode,
      title,
      sessionId,
    });
    setResults(results);
    setScores(scores);
    setScoreGrid(scoreGrid);
    setClassroom(classroom);
    setSubject(subject);

    if (currentQuestion) {
      setCurrentQuestion(currentQuestion);
      if (revealQuestionAnswer && currentQuestion.correctAnswer) {
        setCorrectAnswer(currentQuestion.correctAnswer);
        setExplanation(currentQuestion.explanation);
      }
    } else {
      console.error("No current question received.");
    }

    if (revealQuestionAnswer) {
      setShowAnswers(true);
      setHasSubmitted(true);
    } else {
      setShowAnswers(false);
    }

    if (participantAnswer) {
      setAnswer(participantAnswer);
      setHasSubmitted(true);
    } else {
      console.log("Question not yet answered by participant");
    }

    if (isHost) {
      setMode("hostActive");
    } else {
      setMode("participantActive");
    }
  };

  useEffect(() => {
    socket.on("quizSessionCreated", onServerQuizSessionCreated);
    socket.on("quizSessionJoinSuccess", onServerQuizJoined);
    socket.on("newQuestionStart", onServerQuestionStart);
    socket.on("questionResponseUpdated", onServerUpdateResponse);
    socket.on("revealQuestionAnswers", onServerShowAnswers);
    socket.on("nextQuestion", onServerQuestionStart);
    socket.on("quizSessionEnded", onServerQuizEnd);
    socket.on("finalQuizResults", onServerFinalResults);
    socket.on("participantsUpdated", onServerUpdateParticipants);
    socket.on("scoresUpdated", onServerUpdateScores);
    socket.on("rejoinedQuizSession", handleRejoinedQuizSession);

    return () => {
      socket.off("quizSessionCreated", onServerQuizSessionCreated);
      socket.off("quizSessionJoinSuccess", onServerQuizJoined);
      socket.off("newQuestionStart", onServerQuestionStart);
      socket.off("questionResponseUpdated", onServerUpdateResponse);
      socket.off("revealQuestionAnswers", onServerShowAnswers);
      socket.off("nextQuestion", onServerQuestionStart);
      socket.off("quizSessionEnded", onServerQuizEnd);
      socket.off("finalQuizResults", onServerFinalResults);
      socket.off("participantsUpdated", onServerUpdateParticipants);
      socket.off("scoresUpdated", onServerUpdateScores);
      socket.off("rejoinedQuizSession", handleRejoinedQuizSession);
    };
  }, [
    onServerQuizSessionCreated,
    onServerQuizJoined,
    onServerQuestionStart,
    onServerUpdateResponse,
    onServerShowAnswers,
    handleNextQuestion,
    onServerQuizEnd,
    onServerFinalResults,
    onServerUpdateParticipants,
    onServerUpdateScores,
    initiateQuizJoin,
    participantId,
    loadedQuizId,
    quizId,
    socket,
    handleRejoinedQuizSession,
  ]);

  return {
    initiateQuizJoin,
    submitAnswerToServer,
    reloadQuizSession,
    handleNextQuestion,
  };
};

export default useQuizSessionManager;

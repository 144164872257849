import React, { useEffect, useState } from "react";
import { Box, Typography, TextField } from "@mui/material";
import QuizSyncText from "./QuizSyncText";

const Join = ({ initiateQuizJoin, quizCode, setQuizCode }) => {
  const [localCode, setLocalCode] = useState(["", "", "", ""]); // Local state to hold input values

  useEffect(() => {
    setQuizCode(""); // Reset quizCode to an empty string when component mounts
  }, [setQuizCode]);

  const handleChange = (e, num) => {
    const value = e.target.value.toUpperCase();
    if (/^[0-9]*$/.test(value)) {
      // Ensure only numeric input
      const newCode = [...localCode];
      newCode[num] = value;
      setLocalCode(newCode);

      // Move focus to the next input if valid
      if (value && num < 3) {
        document.getElementById(`code-input-${num + 1}`).focus();
      }

      // Update the main quizCode state when all inputs are filled
      if (newCode.every((val) => val !== "")) {
        const finalCode = newCode.join("");
        setQuizCode(finalCode);
        initiateQuizJoin(finalCode); // Only call initiateQuizJoin here
      }
    }
  };

  const handleBackspace = (e, num) => {
    if (e.key === "Backspace" && !localCode[num] && num > 0) {
      document.getElementById(`code-input-${num - 1}`).focus();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        width: "calc(100% - 50px)", // Make sure the Box takes the remaining width
      }}
    >
      <Typography variant="h4" gutterBottom>
        Join <QuizSyncText>QuizSync</QuizSyncText> Session
      </Typography>
      <Box sx={{ display: "block", width: "100%", maxWidth: 450 }}>
        <Box display="flex" justifyContent="center" gap={2}>
          {localCode.map((value, num) => (
            <TextField
              key={num}
              inputProps={{
                maxLength: 1,
                inputMode: "numeric", // Ensure a numeric keypad appears on mobile
                pattern: "[0-9]*", // Only allow digits
                style: {
                  fontWeight: "bold",
                  fontSize: "20px",
                  letterSpacing: "0.5em",
                  textAlign: "center",
                },
              }}
              margin="normal"
              size="medium"
              value={value}
              onChange={(e) => handleChange(e, num)}
              onKeyDown={(e) => handleBackspace(e, num)}
              autoComplete="off"
              id={`code-input-${num}`}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Join;

import React from "react";
import {
  AppBar as MuiAppBar,
  Toolbar,
  Box,
  IconButton,
  Typography,
  Chip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import UserMenu from "./UserMenu";
import DeleteIcon from "@mui/icons-material/Delete";

const AppBar = ({
  socket,
  activeQuizSession,
  userInfo,
  toggleDrawer,
  setMode,
  reloadQuizSession,
  avatar,
  isLoggedIn,
  setActiveQuizSession,
  quizCode,
  mode,
  anchorEl,
  themeMode,
  toggleThemeMode,
  setUserInfo,
  setAnchorEl,
  setParticipantId,
  setIsLoggedIn,
  participantId,
  setAvatar,
  mixpanel
}) => {
  return (
    <MuiAppBar position="fixed" elevation={0}>
      <Toolbar sx={{ minHeight: "64px" }}>
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src="/logo192.png"
              alt="Logo"
              style={{ height: "40px", marginRight: "16px" }}
            />
            &nbsp;&nbsp;
            <Chip
              label="EXPERIMENTAL"
              variant="outlined"
              size="extra-small"
              style={{
                color: "white",
                borderColor: "white",
                fontSize: "0.625rem",
              }}
            />
          </Box>
          
        </Box>
        {isLoggedIn && (
          <UserMenu
            socket={socket}
            avatar={avatar}
            anchorEl={anchorEl}
            userInfo={userInfo}
            themeMode={themeMode}
            toggleThemeMode={toggleThemeMode}
            setUserInfo={setUserInfo}
            setAnchorEl={setAnchorEl}
            setParticipantId={setParticipantId}
            setIsLoggedIn={setIsLoggedIn}
            participantId={participantId}
            setAvatar={setAvatar}
            mixpanel={mixpanel}
          />
        )}
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;

import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  List,
  ListItem,
  Avatar,
  ListItemText,
  Chip,
  TextField,
  Alert,
  alpha,
  useTheme,
  IconButton,
  Tooltip,
  Dialog,
  DialogContent,
} from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import ShareIcon from "@mui/icons-material/Share";
import QrCodeIcon from "@mui/icons-material/QrCode";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import QRCode from "react-qr-code";

const Lobby = ({
  socket,
  participantId,
  quizCode,
  participants,
  handleStartQuiz,
  activeQuizSession,
  themeMode,
  setClassroom,
  setSubject,
  mixpanel,
}) => {
  const theme = useTheme();
  const [classroomName, setClassroomName] = useState("");
  const [subjectName, setSubjectName] = useState("");
  const [classrooms, setClassrooms] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [error, setError] = useState("");
  const [qrDialogOpen, setQrDialogOpen] = useState(false);

  useEffect(() => {
    socket.emit("getUniqueClassroomsAndSubjects", { participantId });

    socket.on("uniqueClassroomsAndSubjectsSuccess", (data) => {
      setClassrooms(data.classrooms);
      setSubjects(data.subjects);
    });

    return () => {
      socket.off("uniqueClassroomsAndSubjectsSuccess");
    };
  }, [socket, participantId]);

  useEffect(() => {
    if (
      classroomName &&
      subjectName &&
      activeQuizSession &&
      activeQuizSession.sessionId
    ) {
      socket.emit("updateSessionDetails", {
        sessionId: activeQuizSession.sessionId,
        classroomName,
        subjectName,
      });
      setClassroom(classroomName);
      setSubject(subjectName);
    }
  }, [classroomName, subjectName, socket, activeQuizSession]);

  const handleClassroomChange = (event) => {
    setClassroomName(event.target.value);
    setError("");
  };

  const handleSubjectChange = (event) => {
    setSubjectName(event.target.value);
    setError("");
  };

  const validateAndStartQuiz = () => {
    if (!classroomName.trim() || !subjectName.trim()) {
      setError(
        "Please fill in both Classroom and Subject fields before starting the quiz.",
      );
    } else {
      // Mixpanel tracking
      mixpanel.track("Start Quiz", {
        participantId,
        quizCode,
        classroomName,
        subjectName,
        numberOfParticipants: participants.length,
      });
      handleStartQuiz(classroomName, subjectName);
    }
  };

  const handleShareQuizLink = async () => {
    const url = `${window.location.origin}/join/${quizCode}`;
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Join Quiz",
          text: `Join the quiz using the following code: ${quizCode}`,
          url: url,
        });
      } catch (err) {
        console.error("Error sharing:", err);
      }
    } else {
      alert("Sharing is not supported on this browser.");
    }
  };

  const handleCopyLink = () => {
    const url = `${window.location.origin}/join/${quizCode}`;
    navigator.clipboard.writeText(url);
    alert("Quiz link copied to clipboard!");
  };

  const handleOpenQrDialog = () => {
    setQrDialogOpen(true);
  };

  const handleCloseQrDialog = () => {
    setQrDialogOpen(false);
  };

  return (
    <Box
      sx={{
        width: "90%",
        marginLeft: "0px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        elevation={3}
        sx={{
          width: "100%",
          maxWidth: 600,
          marginBottom: 2,
          backgroundColor: themeMode === "light" ? "#ffffff" : "#2c2c2c",
          borderRadius: "8px",
          boxShadow:
            themeMode === "light"
              ? "0 1px 4px rgba(0, 0, 0, 0.1)"
              : "0 1px 4px rgba(0, 0, 0, 0.2)",
          padding: "5px 10px",
        }}
      >
        <CardContent>
          <Typography variant="h5" align="center">
            Lobby
          </Typography>
          <Typography variant="body1" align="center" mt={1}>
            Quiz Code: <strong>{quizCode}</strong>
          </Typography>

          {/* Share, Copy, and QR Code buttons */}
          <Box
            sx={{ display: "flex", justifyContent: "center", gap: 2, my: 2 }}
          >
            <Tooltip title="Share quiz link">
              <IconButton color="primary" onClick={handleShareQuizLink}>
                <ShareIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Copy link to clipboard">
              <IconButton color="primary" onClick={handleCopyLink}>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Show QR code">
              <IconButton color="primary" onClick={handleOpenQrDialog}>
                <QrCodeIcon />
              </IconButton>
            </Tooltip>
          </Box>

          {/* Classroom selection */}
          <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
            Select or Enter Classroom:
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
            {classrooms.map((room) => (
              <Chip
                key={room}
                label={room}
                onClick={() => setClassroomName(room)}
                color={classroomName === room ? "primary" : "default"}
                variant={classroomName === room ? "filled" : "outlined"}
              />
            ))}
          </Box>
          <TextField
            fullWidth
            label="Classroom"
            variant="outlined"
            value={classroomName}
            onChange={handleClassroomChange}
            sx={{ mb: 2 }}
            required
          />

          {/* Subject selection */}
          <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
            Select or Enter Subject:
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
            {subjects.map((subject) => (
              <Chip
                key={subject}
                label={subject}
                onClick={() => setSubjectName(subject)}
                color={subjectName === subject ? "secondary" : "default"}
                variant={subjectName === subject ? "filled" : "outlined"}
              />
            ))}
          </Box>
          <TextField
            fullWidth
            label="Subject"
            variant="outlined"
            value={subjectName}
            onChange={handleSubjectChange}
            sx={{ mb: 2 }}
            required
          />

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <Box
            sx={{
              backgroundColor: alpha(theme.palette.primary.main, 0.08),
              borderRadius: "12px",
              p: 2,
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                mb: 1,
                fontWeight: 600,
              }}
            >
              <GroupIcon color="primary" />
              Participants ({participants.length})
            </Typography>
            <List sx={{ maxHeight: "200px", overflowY: "auto" }}>
              {participants.map((participant, index) => (
                <ListItem
                  key={participant.id}
                  sx={{
                    borderRadius: "8px",
                    mb: index === participants.length - 1 ? 0 : 1,
                    "&:hover": {
                      backgroundColor: alpha(theme.palette.primary.main, 0.1),
                    },
                  }}
                >
                  <Avatar
                    src={participant.avatar}
                    alt={`${participant.name} Avatar`}
                    sx={{
                      mr: 2,
                      width: 40,
                      height: 40,
                      border: `2px solid ${alpha(
                        theme.palette.primary.main,
                        0.2,
                      )}`,
                      backgroundColor: alpha(theme.palette.primary.main, 0.04),
                      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
                    }}
                  />
                  <ListItemText
                    primary={participant.name}
                    sx={{
                      "& .MuiListItemText-primary": {
                        fontWeight: 500,
                      },
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={validateAndStartQuiz}
          >
            Start Quiz
          </Button>
        </CardActions>
      </Card>

      {/* QR Code Dialog */}
      <Dialog
        open={qrDialogOpen}
        onClose={handleCloseQrDialog}
        PaperProps={{
          style: {
            width: "auto",
            height: "auto",
            padding: 0,
            borderRadius: 8,
          },
        }}
      >
        <DialogContent
          style={{
            padding: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <QRCode
              value={`${window.location.origin}/join/${quizCode}`}
              size={400}
              style={{
                backgroundColor: themeMode === "light" ? "#fff" : "#333",
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Lobby;

import React from "react";
import { Box, Typography, LinearProgress } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const FinalResults = ({
  finalResults,
  participantAnswers,
  scores,
  totalParticipants,
  participantId,
  isHost,
  title,
  quizId,
  code,
  setMode,
  themeMode
}) => {
  const getParticipantData = (participantId) => {
    const participant = participantAnswers.find(
      (p) => p.participantId === participantId,
    );
    const score = scores[participantId];
    return { participant, score };
  };

  const renderAggregatedSummary = () => {
    return (
      <Box sx={{ marginBottom: 4 }}>
        {finalResults.map((result, index) => {
          const totalResponses = participantAnswers.length;

          const responseDistribution = result.options.map((option) => {
            const count = participantAnswers.filter(
              (p) => p.answers[index] === option,
            ).length;
            return {
              option,
              count,
              percentage: (count / totalResponses) * 100,
              isCorrect: option === result.correctAnswer,
            };
          });

          return (
            <Box
              key={index}
              sx={{
                padding: 3,
                marginBottom: 3,
                backgroundColor: themeMode === "light" ? "#ffffff" : "#2c2c2c",
                borderRadius: "8px",
                boxShadow:
                  themeMode === "light"
                    ? "0 1px 4px rgba(0, 0, 0, 0.1)"
                    : "0 1px 4px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Typography variant="h6">{`Question ${index + 1}`}</Typography>

              <Typography variant="body1" sx={{ marginY: 2 }}>
                {result.question}
              </Typography>
              {responseDistribution.map((dist, idx) => (
                <Box
                  key={idx}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 1,
                  }}
                >
                  <Typography
                    sx={{
                      marginRight: 2,
                      flexBasis: "15%",
                      fontWeight: "bold",
                    }}
                    variant="body2"
                    color={dist.isCorrect ? "green" : "inherit"}
                  >
                    {dist.option}
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={dist.percentage}
                    sx={{
                      flexGrow: 1,
                      marginRight: 2,
                      height: 10,
                      borderRadius: 5,
                      backgroundColor: "rgba(211, 211, 211, 0.2)", // Grey background for unfilled portion
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: dist.isCorrect
                          ? "rgba(76, 175, 80, 0.8)" // Green for correct
                          : "rgba(244, 67, 54, 0.8)", // Red for incorrect
                      },
                    }}
                  />
                  <Typography variant="body2" sx={{ minWidth: "10%" }}>
                    {`${dist.percentage.toFixed(1)}%`}
                  </Typography>
                </Box>
              ))}
              <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                Explanation: {result.explanation}
              </Typography>
            </Box>
          );
        })}
      </Box>
    );
  };

  const renderDetailedSummary = () => {
    const sortedParticipants = participantAnswers.slice().sort((a, b) => {
      const scoreA = scores[a.participantId];
      const scoreB = scores[b.participantId];
      const totalA = scoreA.correct - scoreA.incorrect;
      const totalB = scoreB.correct - scoreB.incorrect;
      return totalB - totalA;
    });

    return (
      <Box sx={{ marginBottom: 4 }}>
        <Typography variant="h4" gutterBottom>
          Detailed Summary
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          {`Total Participants: ${totalParticipants}`}
        </Typography>
        {sortedParticipants.map((participant, index) => {
          const { participant: participantData, score } = getParticipantData(
            participant.participantId,
          );
          return (
            <Box
              key={index}
              sx={{
                marginBottom: 4,
                padding: 2,
                border: "1px solid #ddd",
                borderRadius: 2,
              }}
            >
              <Typography variant="h6">{`Participant: ${participantData.name}`}</Typography>
              <Typography
                variant="body2"
                sx={{ marginTop: 1, marginBottom: 2 }}
              >
                {`Participant ID: ${participantData.participantId}`}
              </Typography>
              <Typography variant="subtitle1" sx={{ fontStyle: "italic" }}>
                {`Email: ${participantData.email}`}
              </Typography>
              <Typography variant="body2" sx={{ marginTop: 2 }}>
                {`Score: ${score.correct}/${finalResults.length}`}
              </Typography>
              <Box sx={{ marginTop: 2 }}>
                {finalResults.map((result, questionIndex) => {
                  const userAnswer = participantData.answers[questionIndex];
                  const isCorrect = userAnswer === result.correctAnswer;
                  const missed = userAnswer === "";

                  return (
                    <Box
                      key={questionIndex}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 1,
                        padding: 1,
                        border: "1px solid #ddd",
                        borderRadius: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          marginRight: 2,
                          color: missed
                            ? "orange"
                            : isCorrect
                              ? "green"
                              : "red",
                        }}
                      >
                        {isCorrect ? (
                          <CheckCircleIcon color="success" />
                        ) : missed ? (
                          <ErrorOutlineIcon color="warning" />
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </Typography>
                      <Typography variant="body2">
                        {`Q${questionIndex + 1}: ${userAnswer || "No Answer"}`}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          );
        })}
      </Box>
    );
  };

  const renderParticipantView = (participant, score) => {
    return (
      <Box sx={{ padding: 2 }}>
        <Typography variant="h4" gutterBottom>
          {title} - Participant View
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 4 }}>
          {`Quiz ID: ${quizId} | Code: ${code}`}
        </Typography>
        <Box
          sx={{
            marginBottom: 4,
            padding: 2,
            border: "1px solid #ddd",
            borderRadius: 2,
          }}
        >
          <Typography variant="h6">{`Participant: ${participant.name}`}</Typography>
          <Typography variant="subtitle1" sx={{ fontStyle: "italic", mb: 2 }}>
            {`Email: ${participant.email}`}
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: 4 }}>
            {`Score: ${score.correct}/${finalResults.length}`}
          </Typography>
          {finalResults.map((result, index) => {
            const userAnswer = participant.answers[index];
            const isCorrect = userAnswer === result.correctAnswer;
            const missed = userAnswer === "";

            return (
              <Box
                key={index}
                sx={{
                  marginBottom: 4,
                  padding: 2,
                  border: "1px solid #ddd",
                  borderRadius: 2,
                  position: "relative", // To position the icon in the top right
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 24,
                    height: 24,
                    borderRadius: "50%",
                    backgroundColor: missed
                      ? "orange"
                      : isCorrect
                        ? "green"
                        : "red",
                    color: "white",
                  }}
                >
                  {isCorrect ? (
                    <CheckCircleIcon sx={{ fontSize: 18 }} />
                  ) : missed ? (
                    <ErrorOutlineIcon sx={{ fontSize: 18 }} />
                  ) : (
                    <CancelIcon sx={{ fontSize: 18 }} />
                  )}
                </Box>
                <Typography variant="h6">{`Question ${index + 1}`}</Typography>
                <Typography variant="body1" sx={{ marginY: 2 }}>
                  {result.question}
                </Typography>
                <Typography variant="body2" sx={{ marginY: 1 }}>
                  {`Your Answer: ${userAnswer || "No Answer"}`}
                </Typography>
                <Typography variant="body2" sx={{ marginY: 1 }}>
                  {`Correct Answer: ${result.correctAnswer}`}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mt: 2 }}
                >
                  Explanation: {result.explanation}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  };

  if (isHost) {
    return (
      <Box sx={{ padding: 2, width: "80%" }}>
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 4 }}>
          {`Quiz ID: ${quizId} | Code: ${code}`}
        </Typography>
        {renderAggregatedSummary()}
      </Box>
    );
  } else {
    const { participant, score } = getParticipantData(participantId);
    if (!participant) {
      setMode("join");
      return null;
    }
    return renderParticipantView(participant, score);
  }
};

export default FinalResults;

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Rating,
  Stack,
  Chip,
  TextField,
  useTheme,
} from "@mui/material";
import { Send as SendIcon } from "@mui/icons-material";

const QuizFeedback = ({
  socket,
  participantId,
  title,
  setMode,
  sessionId,
  keyConcepts,
}) => {
  const [rating, setRating] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [keyConceptsNotUnderstood, setKeyConceptsNotUnderstood] = useState([]);
  const [comment, setComment] = useState("");
  const theme = useTheme();

  useEffect(() => {
    // Handle feedback submission success or error from the server
    socket.on("feedbackSubmissionSuccess", (message) => {
      console.log("Server response:", message);
      setSubmitted(true);
      setTimeout(() => {
        setMode("join");
      }, 2000);
    });

    socket.on("feedbackSubmissionError", (errorMessage) => {
      alert(`Error: ${errorMessage}`);
    });

    // Clean up the socket connection on unmount
    return () => {
      socket.off("feedbackSubmissionSuccess");
      socket.off("feedbackSubmissionError");
    };
  }, [setMode]);

  const handleConceptToggle = (concept) => {
    setKeyConceptsNotUnderstood((prevConcepts) =>
      prevConcepts.includes(concept)
        ? prevConcepts.filter((c) => c !== concept)
        : [...prevConcepts, concept],
    );
  };

  const onSubmitFeedback = () => {
    if (rating === 0) {
      alert("Please provide a rating before submitting.");
      return;
    }

    const feedback = {
      participantId,
      sessionId,
      rating,
      keyConceptsNotUnderstood,
      comment,
    };

    console.log("Feedback Submitted:", feedback);

    // Emit the feedback data to the server
    socket.emit("submitFeedback", feedback);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        width: "calc(100% - 50px)", // Make sure the Box takes the remaining width
        paddingTop: "20px",
      }}
    >
      {submitted ? (
        <Box textAlign="center">
          <Typography variant="h5" color="success.main" gutterBottom>
            Thank you for your feedback!
          </Typography>
        </Box>
      ) : (
        <Stack spacing={3} sx={{ width: "100%", maxWidth: 600 }}>
          <Typography variant="h4" gutterBottom fontWeight="bold">
            {title} - Feedback
          </Typography>
          <Box
            sx={{
              width: "100%",
              height: "1px",
              bgcolor: theme.palette.divider,
            }}
          />
          <Typography variant="subtitle1" color="text.secondary">
            We value your opinion! Please rate your experience and provide
            feedback.
          </Typography>
          <Box>
            <Typography variant="body2" color="text.secondary">
              Session ID: {sessionId}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6" gutterBottom>
              How would you rate your understanding of this quiz?
            </Typography>
            <Rating
              name="quiz-rating"
              value={rating}
              onChange={(event, newValue) => {
                setRating(newValue || 0);
              }}
              precision={1}
              size="large"
              sx={{ fontSize: "2.5rem" }}
            />
          </Box>
          <Box>
            <Typography variant="h6" gutterBottom>
              Select the key concepts you didn't understand well:
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {keyConcepts.map((concept) => (
                <Chip
                  key={concept}
                  label={concept}
                  onClick={() => handleConceptToggle(concept)}
                  color={
                    keyConceptsNotUnderstood.includes(concept)
                      ? "primary"
                      : "default"
                  }
                  variant={
                    keyConceptsNotUnderstood.includes(concept)
                      ? "filled"
                      : "outlined"
                  }
                />
              ))}
            </Box>
          </Box>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            label="Additional Comments"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={onSubmitFeedback}
            endIcon={<SendIcon />}
            fullWidth
          >
            Submit Feedback
          </Button>
        </Stack>
      )}
    </Box>
  );
};

export default QuizFeedback;

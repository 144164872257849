import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  TextField,
  Button,
} from "@mui/material";

const Dashboard = ({ socket, participantId }) => {
  const [participants, setParticipants] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [dashboardData, setDashboardData] = useState({
    totalQuizzes: 0,
    totalSessions: 0,
  });

  useEffect(() => {
    // Fetch dashboard details when the component mounts
    if (socket && participantId) {
      socket.emit("getDashboardDetails");

      socket.on("dashboardDetailsSuccess", (data) => {
        setDashboardData({
          totalQuizzes: data.totalQuizzes,
          totalSessions: data.totalSessions,
        });
      });

      // Clean up listener when component unmounts
      return () => {
        socket.off("dashboardDetailsSuccess");
      };
    }
  }, [socket, participantId]);

  useEffect(() => {
    const fetchParticipantsWithSessions = async () => {
      socket.emit("getParticipantsWithSessions", {
        participantId: participantId,
      });
    };

    socket.on("participantsWithSessionsSuccess", (data) => {
      setParticipants(data);
    });

    socket.on("socketError", (errorMessage) => {
      console.error("Socket error:", errorMessage);
    });

    fetchParticipantsWithSessions();

    return () => {
      socket.off("participantsWithSessionsSuccess");
      socket.off("socketError");
    };
  }, [socket, participantId]);

  const handleSendMessage = () => {
    if (inputMessage.trim() !== "") {
      setChatMessages([
        ...chatMessages,
        { sender: "User", text: inputMessage },
      ]);
      setInputMessage("");
      // Simulate AI response
      setTimeout(() => {
        setChatMessages((prev) => [
          ...prev,
          { sender: "AI", text: "This is a mock AI response." },
        ]);
      }, 1000);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>

      <Box sx={{ display: "flex", gap: 3 }}>
        <Paper sx={{ p: 2, flex: 1, textAlign: "center" }}>
          <Typography
            variant="h6"
            sx={{
              fontSize: { xs: "1rem", sm: "1.25rem" },
              whiteSpace: "nowrap",
            }}
          >
            Total Quizzes Conducted
          </Typography>
          <Typography
            variant="h3"
            sx={{ fontSize: { xs: "2rem", sm: "2.5rem" } }}
          >
            {dashboardData.totalQuizzes}
          </Typography>
        </Paper>
        <Paper sx={{ p: 2, flex: 1, textAlign: "center" }}>
          <Typography
            variant="h6"
            sx={{
              fontSize: { xs: "1rem", sm: "1.25rem" },
              whiteSpace: "nowrap",
            }}
          >
            Total Sessions Hosted
          </Typography>
          <Typography
            variant="h3"
            sx={{ fontSize: { xs: "2rem", sm: "2.5rem" } }}
          >
            {dashboardData.totalSessions}
          </Typography>
        </Paper>
      </Box>

      {/* Scrollable Participants List */}
      <Paper sx={{ p: 2, height: 300 }}>
        <Typography variant="h6" gutterBottom>
          Participants List
        </Typography>
        <List sx={{ overflowY: "auto" }}>
          {participants.map((participant) => (
            <ListItem key={participant.participantId}>
              <ListItemAvatar>
                <Avatar alt={participant.name} src={participant.avatarUrl} />
              </ListItemAvatar>
              <ListItemText primary={participant.name} />
            </ListItem>
          ))}
        </List>
      </Paper>

      <Paper sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          AI Chat Bot
        </Typography>
        <Box
          sx={{
            height: 200,
            overflowY: "auto",
            mb: 2,
            p: 1,
            border: "1px solid #ccc",
          }}
        >
          {chatMessages.map((msg, index) => (
            <Typography key={index} sx={{ mb: 1 }}>
              <strong>{msg.sender}:</strong> {msg.text}
            </Typography>
          ))}
        </Box>
        <Box sx={{ display: "flex", gap: 1 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Type your message..."
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
          />
          <Button variant="contained" onClick={handleSendMessage}>
            Send
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default Dashboard;

import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  CircularProgress,
  Button,
  Skeleton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Divider,
} from "@mui/material";
import {
  PlayArrow as PlayArrowIcon,
  Shuffle as ShuffleIcon,
  Edit as EditIcon,
  Check as CheckIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { MathJaxContext } from "better-react-mathjax";
import Lottie from "react-lottie";
import correctAnimation from "./animations/loading.json";
import QuizSyncText from "./QuizSyncText";
import GenerateMCQ from "./GenerateMCQ";
import MCQQuestion from "./MCQQuestion";
import EditableQuestion from "./EditableQuestion";

const Create = ({
  socket,
  isProcessing,
  setIsProcessing,
  prompts,
  setPrompts,
  participantId,
  additionalPrompt = "",
  setAdditionalPrompt,
  isAddingQuestion,
  setIsAddingQuestion,
  editableQuestions = [],
  setEditableQuestions,
  quizId = "",
  setQuizId,
  title = "",
  setTitle,
  themeMode,
  mixpanel,
}) => {
  // State variables
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [isGeneratingTitle, setIsGeneratingTitle] = useState(false);
  const [isStartingSession, setIsStartingSession] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editingQuestionId, setEditingQuestionId] = useState(null);
  const [isEditingTitle, setIsEditingTitle] = useState(false); // New state for editing the title
  const [tempTitle, setTempTitle] = useState(title); // Temporary state to hold input value

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // Functions for quiz management
  const saveQuiz = useCallback(() => {
    if (!quizId) {
      console.error("Quiz ID is not set. Cannot save quiz.");
      return;
    }

    const formattedQuestions = editableQuestions.map((question, index) => ({
      order: index,
      contentId: question.contentId,
      type: question.type,
      data: {
        question: question.question,
        options: question.options,
        correctAnswer: question.correctAnswer,
        explanation: question.explanation,
        skill_tag: question.skill_tag,
        cognitive_level: question.cognitive_level,
        key_concept: question.key_concept,
      },
    }));
    socket.emit("saveQuiz", {
      quizId: quizId,
      questions: formattedQuestions,
      title: title,
      topic: searchQuery,
      participantId: participantId,
    });
  }, [socket, quizId, editableQuestions, title, searchQuery, participantId]);

  // Listener for quizIdGenerated
  useEffect(() => {
    const handleQuizIdGenerated = (data) => {
      setQuizId(data.quizId);
      console.log("Quiz ID received:", data.quizId);
      saveQuiz(); // Save the quiz now that we have the quizId
    };
    socket.on("quizIdGenerated", handleQuizIdGenerated);
    return () => {
      socket.off("quizIdGenerated", handleQuizIdGenerated);
    };
  }, [socket, saveQuiz, setQuizId]);

  useEffect(() => {
    if (editableQuestions.length > 0) {
      if (!quizId) {
        socket.emit("generateQuizId");
      } else {
        saveQuiz();
      }
    }
  }, [socket, editableQuestions, quizId, saveQuiz]);

  const handleGenerateTitle = useCallback(() => {
    setIsGeneratingTitle(true);
    socket.emit("generateTitle", { content: editableQuestions });
  }, [socket, editableQuestions]);

  useEffect(() => {
    if (editableQuestions.length === 1 && !title) {
      handleGenerateTitle();
    }
  }, [editableQuestions.length, title, handleGenerateTitle]);

  useEffect(() => {
    socket.on("titleGeneratedSuccess", ({ title }) => {
      setTitle(title);
      setIsGeneratingTitle(false);
    });

    socket.on("socketError", (message) => {
      console.error("Socket error:", message);
      setIsGeneratingTitle(false);
    });

    return () => {
      socket.off("titleGeneratedSuccess");
      socket.off("socketError");
    };
  }, [socket, setTitle, setIsGeneratingTitle]);

  const handleCreateQuizSession = useCallback(() => {
    if (!participantId) return;
    setIsStartingSession(true);
    socket.emit("createQuizSession", {
      quizId,
      participantId,
      questions: editableQuestions,
      title,
      topic: searchQuery,
    });
    mixpanel.track("Start Session", {
      quizId,
      title,
      numberOfQuestions: editableQuestions.length,
    });
  }, [
    socket,
    quizId,
    participantId,
    editableQuestions,
    title,
    searchQuery,
    mixpanel,
  ]);

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleAnswerSelect = (questionIndex, selectedOption) => {
    setSelectedAnswers((prev) => ({
      ...prev,
      [questionIndex]: selectedOption,
    }));
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: correctAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleAddToQuiz = (suggestion) => {
    const newQuestion = {
      type: "MCQ",
      question: suggestion.sampleMCQ.question,
      options: suggestion.sampleMCQ.options,
      correctAnswer: suggestion.sampleMCQ.correctAnswer,
      explanation: suggestion.sampleMCQ.explanation,
    };
    setEditableQuestions((prevQuestions) => [...prevQuestions, newQuestion]);
  };

  // Editable Questions Handlers
  const handleQuestionChange = useCallback(
    (index, event) => {
      const newQuestions = [...editableQuestions];
      newQuestions[index].question = event.target.value;
      setEditableQuestions(newQuestions);
    },
    [setEditableQuestions, editableQuestions],
  );

  const handleTitleEditToggle = () => {
    setIsEditingTitle(!isEditingTitle);
    setTempTitle(title);
  };

  const handleTitleInputChange = (event) => {
    setTempTitle(event.target.value);
  };

  const handleTitleSave = () => {
    setTitle(tempTitle);
    setIsEditingTitle(false);
    mixpanel.track("Edit Title", { title: tempTitle });
  };

  const handleOptionChange = useCallback(
    (qIndex, oIndex, event) => {
      const newQuestions = [...editableQuestions];
      newQuestions[qIndex].options[oIndex] = event.target.value;
      setEditableQuestions(newQuestions);
    },
    [setEditableQuestions, editableQuestions],
  );

  const handleCorrectAnswerChange = useCallback(
    (index, event) => {
      const newQuestions = [...editableQuestions];
      newQuestions[index].correctAnswer = event.target.value;
      setEditableQuestions(newQuestions);
    },
    [setEditableQuestions, editableQuestions],
  );

  const handleExplanationChange = useCallback(
    (index, event) => {
      const newQuestions = [...editableQuestions];
      newQuestions[index].explanation = event.target.value;
      setEditableQuestions(newQuestions);
    },
    [setEditableQuestions, editableQuestions],
  );

  const handleDeleteQuestion = useCallback(
    (index) => {
      const deletedQuestion = editableQuestions[index];
      const newQuestions = editableQuestions.filter(
        (_, qIndex) => qIndex !== index,
      );
      setEditableQuestions(newQuestions);
      mixpanel.track("Delete Question", {
        questionIndex: index,
        questionContentId: deletedQuestion.contentId,
      });
    },
    [setEditableQuestions, editableQuestions, mixpanel],
  );

  const moveArrayItem = (arr, fromIndex, toIndex) => {
    if (toIndex >= 0 && toIndex < arr.length) {
      const newArr = [...arr];
      const [movedItem] = newArr.splice(fromIndex, 1);
      newArr.splice(toIndex, 0, movedItem);
      return newArr;
    }
    return arr;
  };

  const handleMoveQuestionUp = (contentId) => {
    const index = editableQuestions.findIndex((q) => q.contentId === contentId);
    if (index > 0) {
      setEditableQuestions((prevQuestions) =>
        moveArrayItem(prevQuestions, index, index - 1),
      );
    }
  };

  const handleMoveQuestionDown = (contentId) => {
    const index = editableQuestions.findIndex((q) => q.contentId === contentId);
    if (index < editableQuestions.length - 1) {
      setEditableQuestions((prevQuestions) =>
        moveArrayItem(prevQuestions, index, index + 1),
      );
    }
  };

  const handleSkillTagChange = useCallback(
    (index, event) => {
      const newQuestions = [...editableQuestions];
      newQuestions[index].skill_tag = event.target.value;
      setEditableQuestions(newQuestions);
    },
    [setEditableQuestions, editableQuestions],
  );

  const handleCognitiveLevelChange = useCallback(
    (index, event) => {
      const newQuestions = [...editableQuestions];
      newQuestions[index].cognitive_level = event.target.value;
      setEditableQuestions(newQuestions);
    },
    [setEditableQuestions, editableQuestions],
  );

  const handleKeyConceptChange = useCallback(
    (index, event) => {
      const newQuestions = [...editableQuestions];
      newQuestions[index].key_concept = event.target.value;
      setEditableQuestions(newQuestions);
    },
    [setEditableQuestions, editableQuestions],
  );

  useEffect(() => {
    const handlePromptsGeneratedSuccess = (data) => {
      setPrompts(data.prompts);
      setIsProcessing(false);
    };

    socket.on("promptsGeneratedSuccess", handlePromptsGeneratedSuccess);

    return () => {
      socket.off("promptsGeneratedSuccess", handlePromptsGeneratedSuccess);
    };
  }, [socket, setIsProcessing, setPrompts]);

  useEffect(() => {
    setPrompts([]);
  }, [setPrompts]);

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleSimultaneousRealTimeSession = () => {
    handleCreateQuizSession();
    handleDialogClose();
  };

  if (isStartingSession) {
    return (
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Lottie options={defaultOptions} height={200} width={200} />
      </Box>
    );
  }

  return (
    <MathJaxContext>
      {/* Parent Container to hold the main content */}
      <Box
        sx={{
          width: "90%",
          marginLeft: "50px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* Main Content */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            overflowY: "hidden",
            zIndex: 1,
          }}
        >
          {/* Heading */}
          {editableQuestions.length === 0 && (
            <Typography variant="h4" gutterBottom>
              Create a <QuizSyncText>QuizSync</QuizSyncText>
            </Typography>
          )}

          <Box sx={{ width: "100%", maxWidth: "800px", mt: 2 }}>
            {/* Start Session and Quiz ID */}
            {editableQuestions.length > 0 && (
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                mb={2}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDialogOpen}
                  startIcon={<PlayArrowIcon />}
                >
                  Start Session
                </Button>
              </Box>
            )}

            {/* Dialog for Starting Session */}
            <Dialog
              open={isDialogOpen}
              onClose={handleDialogClose}
              maxWidth="sm"
              fullWidth
              sx={{
                borderRadius: "12px",
                boxShadow:
                  themeMode === "light"
                    ? "0 4px 12px rgba(0, 0, 0, 0.1)"
                    : "0 4px 12px rgba(0, 0, 0, 0.3)",
                border: "1px solid",
                borderColor:
                  themeMode === "light"
                    ? "rgba(0, 0, 0, 0.12)"
                    : "rgba(255, 255, 255, 0.12)",
              }}
            >
              <DialogTitle
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.25rem",
                  color:
                    themeMode === "light" ? "text.primary" : "text.secondary",
                }}
              >
                Start Quiz Session
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  sx={{
                    fontSize: "1rem",
                    color: "primary",
                    marginBottom: 3,
                  }}
                >
                  Choose the type of session you want to start:
                </DialogContentText>
                <Box sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSimultaneousRealTimeSession}
                    sx={{
                      mb: 2,
                      height: "60px",
                      fontSize: "1rem",
                      fontWeight: 500,
                      textTransform: "none",
                    }}
                  >
                    Simultaneous Real-Time Quiz Session
                  </Button>
                  <Typography
                    variant="body2"
                    sx={{
                      mb: 2,
                      color:
                        themeMode === "light"
                          ? "text.secondary"
                          : "text.primary",
                    }}
                  >
                    Simultaneous Real-Time Quiz collects responses for all
                    questions at the same time. The questions are controlled by
                    the teacher.
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Button
                    variant="contained"
                    color="primary"
                    disabled
                    sx={{
                      height: "60px",
                      fontSize: "1rem",
                      fontWeight: 500,
                      textTransform: "none",
                      mb: 2,
                    }}
                  >
                    Exit Ticket (Coming Soon)
                  </Button>
                  <Typography
                    variant="body2"
                    sx={{
                      color:
                        themeMode === "light"
                          ? "text.secondary"
                          : "text.primary",
                    }}
                  >
                    Exit Ticket allows participants to respond at their own pace
                    and is typically used at the end of a class. (This feature
                    is under development)
                  </Typography>
                </Box>
              </DialogContent>
              <DialogActions sx={{ px: 3, pb: 2 }}>
                <Button
                  onClick={handleDialogClose}
                  color="primary"
                  sx={{
                    textTransform: "none",
                    fontWeight: 500,
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>

            {/* Title with editing functionality */}
            {editableQuestions.length > 0 && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mb={2}
              >
                {isEditingTitle ? (
                  <Box display="flex" alignItems="center">
                    <TextField
                      value={tempTitle}
                      onChange={handleTitleInputChange}
                      size="small"
                      variant="outlined"
                      sx={{ mr: 1 }}
                    />
                    <IconButton onClick={handleTitleSave}>
                      <CheckIcon />
                    </IconButton>
                    <IconButton onClick={handleTitleEditToggle}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                ) : (
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      sx={{ textAlign: "center", mr: 1 }}
                    >
                      {title}
                    </Typography>
                    <IconButton onClick={handleTitleEditToggle}>
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={handleGenerateTitle}
                      disabled={isGeneratingTitle}
                    >
                      {isGeneratingTitle ? (
                        <CircularProgress size={24} />
                      ) : (
                        <ShuffleIcon />
                      )}
                    </IconButton>
                  </Box>
                )}
              </Box>
            )}

            {/* Question List */}
            <Box sx={{ width: "100%" }}>
              {editableQuestions.map((question, qIndex) =>
                editingQuestionId === question.contentId ? (
                  <EditableQuestion
                    key={`editable-question-${qIndex}`}
                    question={question}
                    questionIndex={qIndex}
                    handleQuestionChange={handleQuestionChange}
                    handleOptionChange={handleOptionChange}
                    handleCorrectAnswerChange={handleCorrectAnswerChange}
                    handleExplanationChange={handleExplanationChange}
                    handleDeleteQuestion={handleDeleteQuestion}
                    handleMoveQuestionUp={handleMoveQuestionUp}
                    handleMoveQuestionDown={handleMoveQuestionDown}
                    handleSkillTagChange={handleSkillTagChange}
                    handleCognitiveLevelChange={handleCognitiveLevelChange}
                    handleKeyConceptChange={handleKeyConceptChange}
                    editableQuestions={editableQuestions}
                    onClose={() => setEditingQuestionId(null)}
                    themeMode={themeMode}
                  />
                ) : (
                  <MCQQuestion
                    key={`mcq-question-${qIndex}`}
                    question={question}
                    questionIndex={qIndex}
                    selectedAnswer={selectedAnswers[qIndex]}
                    handleAnswerSelect={handleAnswerSelect}
                    onEdit={() => setEditingQuestionId(question.contentId)}
                    onDelete={() => handleDeleteQuestion(qIndex)}
                    onMoveUp={() => handleMoveQuestionUp(question.contentId)}
                    onMoveDown={() =>
                      handleMoveQuestionDown(question.contentId)
                    }
                    editableQuestions={editableQuestions}
                    themeMode={themeMode}
                  />
                ),
              )}
              {isAddingQuestion && (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={425}
                  sx={{
                    marginBottom: 2,
                    borderRadius: "8px",
                    marginTop: editableQuestions.length > 0 ? 0 : 7,
                  }}
                />
              )}
            </Box>

            {/* Generate MCQ Component */}
            <GenerateMCQ
              additionalPrompt={additionalPrompt}
              setAdditionalPrompt={setAdditionalPrompt}
              socket={socket}
              quizId={quizId}
              editableQuestions={editableQuestions}
              setIsAddingQuestion={setIsAddingQuestion}
              setEditableQuestions={setEditableQuestions}
              setQuizId={setQuizId}
              isAddingQuestion={isAddingQuestion}
              themeMode={themeMode}
              mixpanel={mixpanel}
            />
          </Box>
        </Box>
      </Box>
    </MathJaxContext>
  );
};

export default Create;

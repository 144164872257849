import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Divider,
  Avatar,
  Chip,
  Button,
  Paper,
  List,
  ListItem,
  Collapse,
  CircularProgress,
} from "@mui/material";

const ParticipantList = ({ socket, participantId, themeMode, mixpanel }) => {
  const [participants, setParticipants] = useState([]);
  const [detailedParticipant, setDetailedParticipant] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(""); // Combined filter state
  const [expandedSession, setExpandedSession] = useState(null); // State to track expanded session
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    mixpanel.track("ParticipantList Loaded", {
      participantId: participantId,
    });

    const fetchParticipantsWithSessions = async () => {
      socket.emit("getParticipantsWithSessions", {
        participantId: participantId,
      });
    };

    socket.on("participantsWithSessionsSuccess", (data) => {
      setParticipants(data);
    });

    socket.on("participantResponsesSuccess", (data) => {
      setDetailedParticipant((prevState) => ({
        ...prevState,
        responses: data,
      }));
      setIsLoading(false);
    });

    socket.on("socketError", (errorMessage) => {
      console.error("Socket error:", errorMessage);
    });

    fetchParticipantsWithSessions();

    return () => {
      socket.off("participantsWithSessionsSuccess");
      socket.off("participantResponsesSuccess");
      socket.off("socketError");
    };
  }, [socket, participantId, mixpanel]);

  const handleParticipantClick = useCallback(
    (participant) => {
      setDetailedParticipant({ ...participant, responses: [] });
      setIsLoading(true);
      socket.emit("getParticipantResponses", {
        hostId: participantId,
        participantId: participant.participantId,
      });
    },
    [socket, participantId],
  );

  const handleBack = () => {
    setDetailedParticipant(null);
  };

  const handleSessionToggle = (sessionId) => {
    setExpandedSession((prevSession) =>
      prevSession === sessionId ? null : sessionId,
    );
  };

  // Generate unique classroom-subject combinations
  const uniqueFilters = useMemo(() => {
    return [
      ...new Set(
        participants.flatMap((participant) =>
          participant.classrooms.map(
            (classroom, index) =>
              `${classroom} - ${participant.subjects[index] || "Untitled"}`,
          ),
        ),
      ),
    ];
  }, [participants]);

  // Filter participants based on the selected classroom-subject combination
  const filteredParticipants = useMemo(() => {
    return participants.filter((participant) =>
      participant.classrooms.some((classroom, index) => {
        const filterString = `${classroom} - ${
          participant.subjects[index] || "Untitled"
        }`;
        return selectedFilter === "" || filterString === selectedFilter;
      }),
    );
  }, [participants, selectedFilter]);

  // Group responses by session ID and apply the selected filter
  const groupedResponsesBySession = useMemo(() => {
    if (!detailedParticipant) return [];

    // Group responses by sessionId and filter based on selected classroom-subject combination
    const grouped = detailedParticipant.responses.reduce((acc, response) => {
      const sessionId = response.sessionId;
      const classroomSubjectCombo = `${response.classroom || "Untitled Classroom"} - ${
        response.subject || "Untitled Subject"
      }`;

      // Apply the selected filter to the sessions
      if (selectedFilter && classroomSubjectCombo !== selectedFilter) {
        return acc;
      }

      if (!acc[sessionId]) {
        acc[sessionId] = {
          summary: response.summary,
          date: response.created_at,
          classroom: response.classroom || "Untitled Classroom",
          subject: response.subject || "Untitled Subject",
          responses: [],
        };
      }
      // Add the response under the corresponding session ID
      acc[sessionId].responses.push(response);

      return acc;
    }, {});

    // Sort sessions by date
    return Object.entries(grouped).sort(
      ([, a], [, b]) => new Date(b.date) - new Date(a.date),
    );
  }, [detailedParticipant, selectedFilter]);

  if (detailedParticipant) {
    return (
      <Box
        sx={{
          width: "80%",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <Button variant="outlined" onClick={handleBack} sx={{ mb: 2 }}>
          Back to Participants List
        </Button>
        <Paper
          elevation={3}
          sx={{
            padding: 3,
            width: "100%",
            marginBottom: 3,
            backgroundColor: themeMode === "light" ? "#ffffff" : "#2c2c2c",
            borderRadius: "8px",
            boxShadow:
              themeMode === "light"
                ? "0 1px 4px rgba(0, 0, 0, 0.1)"
                : "0 1px 4px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Avatar
              alt={detailedParticipant.name}
              src={detailedParticipant.avatarUrl}
              sx={{ width: 80, height: 80, mr: 2 }}
            />
            <Box>
              <Typography variant="h4" sx={{ mb: 1 }}>
                {detailedParticipant.name}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {detailedParticipant.email}
              </Typography>
            </Box>
          </Box>
        </Paper>

        <Typography variant="h5" sx={{ mb: 2 }}>
          Quiz Responses Grouped by Session
        </Typography>
        <Divider sx={{ mb: 2, width: "100%" }} />

        {/* Added loading state UI */}
        {isLoading ? (
          <Box sx={{ width: "100%", textAlign: "center", py: 4 }}>
            <CircularProgress />
            <Typography variant="body1" sx={{ mt: 2 }}>
              Loading quiz sessions...
            </Typography>
          </Box>
        ) : (
          <List sx={{ width: "100%" }}>
            {groupedResponsesBySession.map(([sessionId, sessionData]) => {
              let parsedSummary;

              // Adjusted code to check if sessionData.summary exists and is valid
              if (sessionData.summary) {
                try {
                  parsedSummary = JSON.parse(sessionData.summary);
                } catch {
                  parsedSummary = {
                    individualPerformance: "Summary parsing failed.",
                    personalImprovementAreas: [],
                  };
                }
              } else {
                parsedSummary = {
                  individualPerformance: "No summary available.",
                  personalImprovementAreas: [],
                };
              }

              return (
                // Modified Box component to remove margin bottom
                <Box key={sessionId}>
                  <Card
                    sx={{
                      width: "100%",
                      backgroundColor:
                        themeMode === "light" ? "#ffffff" : "#2c2c2c",
                      borderRadius: "8px",
                      // Modified border radius when expanded
                      borderBottomLeftRadius:
                        expandedSession === sessionId ? 0 : "8px",
                      borderBottomRightRadius:
                        expandedSession === sessionId ? 0 : "8px",
                      boxShadow:
                        themeMode === "light"
                          ? "0 1px 4px rgba(0, 0, 0, 0.1)"
                          : "0 1px 4px rgba(0, 0, 0, 0.2)",
                      transition: "all 0.3s ease-in-out",
                      border: "1px solid transparent",
                      "&:hover": {
                        borderColor:
                          expandedSession === sessionId ? undefined : "#5e52f7",
                        boxShadow:
                          themeMode === "light"
                            ? "0 2px 6px rgba(0, 0, 0, 0.1)"
                            : "0 2px 6px rgba(0, 0, 0, 0.2)",
                      },
                      // Added marginBottom only when not expanded
                      mb: expandedSession === sessionId ? 0 : 2,
                      // Add bottom border when expanded
                      borderBottom:
                        expandedSession === sessionId ? "none" : undefined,
                    }}
                    onClick={() => handleSessionToggle(sessionId)}
                  >
                    <Typography variant="h6" sx={{ padding: "10px 10px 5px" }}>
                      {sessionData.responses[0].quizTitle}
                    </Typography>
                    <CardContent>
                      <Box sx={{ display: "flex", gap: 1, mb: 1 }}>
                        <Chip
                          label={sessionData.classroom}
                          color="primary"
                          size="small"
                        />
                        <Chip
                          label={sessionData.subject}
                          color="secondary"
                          size="small"
                        />
                      </Box>
                      <Typography variant="body1">
                        <strong>Individual Performance:</strong>{" "}
                        {parsedSummary.individualPerformance}
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        <strong>Date:</strong>{" "}
                        {new Date(sessionData.date).toLocaleString()}
                      </Typography>
                    </CardContent>
                  </Card>

                  {/* Modified Collapse to remove gap */}
                  <Collapse
                    in={expandedSession === sessionId}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Paper
                      elevation={2}
                      sx={{
                        // Modified styling to connect with the card above
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        borderTop: "none",
                        mb: 2, // Added margin bottom for spacing between sessions
                        p: 2,
                        boxShadow:
                          themeMode === "light"
                            ? "0 2px 6px rgba(0, 0, 0, 0.1)"
                            : "0 2px 6px rgba(0, 0, 0, 0.2)",
                      }}
                    >
                      {parsedSummary.personalImprovementAreas &&
                        parsedSummary.personalImprovementAreas.length > 0 && (
                          <>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                              <strong>Areas for Improvement:</strong>
                            </Typography>
                            <List dense>
                              {parsedSummary.personalImprovementAreas.map(
                                (area, index) => (
                                  <ListItem key={index}>
                                    <Typography variant="body2">
                                      - {area}
                                    </Typography>
                                  </ListItem>
                                ),
                              )}
                            </List>
                            <Divider sx={{ mt: 2, mb: 2 }} />
                          </>
                        )}

                      <List>
                        {sessionData.responses.map((response) => (
                          <ListItem
                            key={response.responseId}
                            alignItems="flex-start"
                            sx={{
                              border: "1px dotted",
                              mb: 2,
                              borderRadius: 1,
                            }}
                          >
                            <Box sx={{ width: "100%" }}>
                              <Typography variant="body2" sx={{ mb: 0.5 }}>
                                <strong>Question:</strong>{" "}
                                {response.questionData.question}
                              </Typography>
                              <Typography variant="body2" sx={{ mb: 0.5 }}>
                                <strong>Your Answer:</strong>{" "}
                                {response.response}
                              </Typography>
                              <Typography variant="body2" sx={{ mb: 0.5 }}>
                                <strong>Correct Answer:</strong>{" "}
                                {response.questionData.correctAnswer}
                              </Typography>
                              <Typography variant="body2" sx={{ mb: 0.5 }}>
                                <strong>Score:</strong> {response.score}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                <strong>Explanation:</strong>{" "}
                                {response.questionData.explanation}
                              </Typography>
                            </Box>
                          </ListItem>
                        ))}
                      </List>

                      {sessionData.responses[0].feedback && (
                        <Box sx={{ mt: 3 }}>
                          <Typography
                            variant="h6"
                            sx={{ mb: 1, fontWeight: "bold", fontSize: "1rem" }}
                          >
                            Student Feedback
                          </Typography>

                          {sessionData.responses[0].feedback
                            .key_concept_not_understood &&
                            sessionData.responses[0].feedback
                              .key_concept_not_understood !== "" && (
                              <Box sx={{ mb: 1 }}>
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    mb: 1,
                                    fontWeight: "medium",
                                    fontSize: "0.9rem",
                                  }}
                                >
                                  Key Concepts Not Understood:
                                </Typography>
                                <List dense disablePadding>
                                  {JSON.parse(
                                    sessionData.responses[0].feedback
                                      .key_concept_not_understood,
                                  ).map((area, index) => (
                                    <ListItem key={index} sx={{ py: 0.5 }}>
                                      <Typography
                                        variant="body2"
                                        sx={{ fontSize: "0.9rem" }}
                                      >
                                        • {area}
                                      </Typography>
                                    </ListItem>
                                  ))}
                                </List>
                              </Box>
                            )}

                          {sessionData.responses[0].feedback.comments &&
                            sessionData.responses[0].feedback.comments !==
                              "" && (
                              <Box sx={{ mb: 1 }}>
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    mb: 1,
                                    fontWeight: "medium",
                                    fontSize: "0.9rem",
                                  }}
                                >
                                  Comments:
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{ pl: 2, fontSize: "0.9rem" }}
                                >
                                  {sessionData.responses[0].feedback.comments}
                                </Typography>
                              </Box>
                            )}

                          {sessionData.responses[0].feedback.rating && (
                            <Box sx={{ mt: 1 }}>
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  mb: 1,
                                  fontWeight: "medium",
                                  fontSize: "0.9rem",
                                }}
                              >
                                Rating:{" "}
                                {sessionData.responses[0].feedback.rating} / 5
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      )}
                    </Paper>
                  </Collapse>
                </Box>
              );
            })}
          </List>
        )}

        {!isLoading && groupedResponsesBySession.length === 0 && (
          <Typography variant="body1" sx={{ mt: 2 }}>
            No quiz responses available for this participant.
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: "90%",
        marginLeft: "50px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="h4" sx={{ mb: 2 }}>
        Participants
      </Typography>
      <Divider sx={{ mb: 2, width: "100%" }} />

      {/* Filter Chips for Classroom and Subject Combinations */}
      <Box
        sx={{
          display: "flex",
          gap: 1,
          width: "100%",
          flexWrap: "wrap",
          justifyContent: "center",
          mb: 4,
        }}
      >
        {uniqueFilters.map((filter) => (
          <Chip
            key={filter}
            label={filter}
            clickable
            color={selectedFilter === filter ? "primary" : "default"}
            onClick={() =>
              setSelectedFilter((prevFilter) =>
                prevFilter === filter ? "" : filter,
              )
            }
          />
        ))}
      </Box>

      <Grid container spacing={2}>
        {filteredParticipants.map((participant) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            key={participant.participantId}
          >
            <Card
              sx={{
                width: "100%",
                height: 200,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                cursor: "pointer",
                backgroundColor: themeMode === "light" ? "#ffffff" : "#2c2c2c",
                borderRadius: "12px",
                boxShadow:
                  themeMode === "light"
                    ? "0 2px 8px rgba(0, 0, 0, 0.08)"
                    : "0 2px 8px rgba(0, 0, 0, 0.15)",
                transition: "all 0.3s ease-in-out",
                border: "1px solid transparent",
                "&:hover": {
                  borderColor: "#5e52f7",
                  boxShadow: "0 4px 12px rgba(94, 82, 247, 0.2)",
                  transform: "translateY(-3px)",
                },
              }}
              onClick={() => handleParticipantClick(participant)}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  textAlign: "center",
                  padding: "16px",
                }}
              >
                <Avatar
                  alt={participant.name}
                  src={participant.avatarUrl}
                  sx={{
                    width: 60,
                    height: 60,
                    mb: 2,
                  }}
                />
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {participant.name}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    wordBreak: "break-word", // Allow email to break onto multiple lines if necessary
                  }}
                >
                  {participant.email}
                </Typography>{" "}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ParticipantList;
